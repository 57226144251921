import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import Button from '../../../shared-components/button/button';

function SetAudio({ closeModal, data }: any) {
  const { t } = useTranslation();
  const [eventSound, setEventSound] = useState('');
  const submit = () => {
    console.log('hi');
  };
  const clickPlay = () => {
    console.log('hi');
  };
  console.log('dat', data); // temp
  return (
    <div className="set-audio">
      <div className="flex-row">
        <SelectBoxField
          name="eventSound"
          label={t('eventSound')}
          id="eventSound"
          value={eventSound}
          onChangeFunction={(name, value: any) => setEventSound(value.id)}
          data={[
            { label: t('5-2-21-2.wave'), id: 'wave' },
            { label: t('alarm'), id: 'alarm' },
          ]}
          className=""
        />
        <Button
          onClickFunction={clickPlay}
          title={t('play')}
          className="btn-primary"
          disabled={!eventSound}
        />
      </div>
      <div className="form-buttons-right">
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('cancel')}
          className="btn-default btn-bold"
        />
        <Button
          onClickFunction={submit}
          title={t('ok')}
          className="btn-primary btn-bold"
          disabled={!eventSound}
        />
      </div>
    </div>
  );
}

export default SetAudio;
