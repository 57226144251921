import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import Button from '../../../shared-components/button/button';

function AlarmProcessingHistory({ closeModal, data }: any) {
  const { t } = useTranslation();
  const [state, setState] = useState({
    eventDescription: data?.eventDescription || '',
    deviceName: data?.device || '',
    eventPoint: '',
  });
  const onChangeValue = (name: string, value: any) => {
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <div className="acknowledge" style={{ width: '620px' }}>
      <div className="flex-row column">
        <div className="input-row">
          <InputField
            name="eventDescription"
            label={t('eventDescription')}
            id="eventDescription"
            type="text"
            value={state?.eventDescription}
            onChangeFunction={onChangeValue}
            disabled
          />
          <InputField
            name="deviceName"
            label={t('deviceName')}
            id="deviceName"
            type="text"
            value={state?.deviceName}
            onChangeFunction={onChangeValue}
            disabled
          />
          <InputField
            name="eventPoint"
            label={t('eventPoint')}
            id="eventPoint"
            type="text"
            value={state?.eventPoint}
            onChangeFunction={onChangeValue}
            disabled
          />
        </div>
        <div className="row-1">
          <h2>{t('processingRecords')}</h2>
          <div className="box-1" />
        </div>
        <div className="form-buttons-right">
          <Button onClickFunction={closeModal} title={t('cancel')} className="btn-primary" />
        </div>
      </div>
    </div>
  );
}

export default AlarmProcessingHistory;
