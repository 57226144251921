import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// Use Translation
import { useTranslation } from 'react-i18next';

// Use interface
import { SideBarSessionInterFace, SubmenuInterFace } from '../../interface/common.interface';
import {
  AccessDeviceIcon,
  AccessRuleIcon,
  CardHandIcon,
  DownArrowIcon,
  PersonIcon,
} from '../../assets/svgicons/svgicon';

// use assets image files
import logo from '../../assets/images/logo.png';
import logo1 from '../../assets/images/logo1.png';

function SidebarSession({ menus, redirect, activeValue, openDropDown }: SideBarSessionInterFace) {
  const { Icon, RightIcon, label, active, children } = menus;
  return (
    <div className={`sidebar-menu-session ${activeValue === active ? 'active' : ''}`}>
      <div
        className={`main-menu ${activeValue === active ? 'active' : ''} `}
        onClick={() => openDropDown(active)}
        role="presentation">
        <div className="menu-icon">
          <Icon color={activeValue === active ? '#078DEE' : '#3E4246'} />
        </div>
        <div className="menu-label">{label}</div>
        <div className="menu-dropdown-icon">
          <RightIcon color={activeValue === active ? '#078DEE' : '#3E4246'} />
        </div>
      </div>
      <div className={`menu-dropdown ${activeValue === active ? 'active' : ''}`}>
        {children.map((menu: SubmenuInterFace) => (
          <Link
            to={menu.url}
            key={menu.url}
            className={`sub-menu ${window.location.pathname === menu.activeURL && 'active'}`}
            onClick={() => redirect(menu.url, active)}>
            {menu.label}
          </Link>
        ))}
      </div>
      <div className={`tab-menu ${activeValue === active ? 'active' : ''}`}>
        <div className="menu-icon">
          <Icon color={activeValue === 'active' ? '#078DEE' : '#3E4246'} />
        </div>
        <div className="menus-list">
          <div className="menu-label">{label}</div>
          {children.map((menu: SubmenuInterFace) => (
            <div
              key={menu.url}
              className={`sub-menu ${window.location.pathname === menu.activeURL && 'active'}`}
              role="presentation"
              onClick={() => redirect(menu.url, active)}>
              {menu.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function Sidebar() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentPath = window.location.pathname;
  const parts = currentPath.split('/');
  const slicedString = parts.slice(0, 3).join('/');

  const sideBarMenu = [
    {
      icon: PersonIcon,
      rightIcon: DownArrowIcon,
      name: t('personnel'),
      activeURL: '/admin/personnel',
      key: 'personnel',
      children: [
        {
          label: t('person'),
          url: 'personnel/personnel/person',
          activeURL: '/admin/personnel/personnel/person',
        },
        {
          label: t('department'),
          url: 'personnel/personnel/department',
          activeURL: '/admin/personnel/personnel/department',
        },
        {
          label: t('position'),
          url: 'personnel/personnel/position',
          activeURL: '/admin/personnel/personnel/position',
        },
        {
          label: t('dismissedPersonnel'),
          url: 'personnel/personnel/dismissed-personnel',
          activeURL: '/admin/personnel/personnel/dismissed-personnel',
        },
        {
          label: t('pendingReview'),
          url: 'personnel/personnel/pending-review',
          activeURL: '/admin/personnel/personnel/pending-review',
        },
        {
          label: t('customAttributes'),
          url: 'personnel/personnel/custom-attributes',
          activeURL: '/admin/personnel/personnel/custom-attributes',
        },
        {
          label: t('listLibrary'),
          url: 'personnel/personnel/list-library',
          activeURL: '/admin/personnel/personnel/list-library',
        },
        {
          label: t('parameters'),
          url: 'personnel/personnel/parameters',
          activeURL: '/admin/personnel/personnel/parameters',
        },
      ],
    },
    {
      icon: CardHandIcon,
      rightIcon: DownArrowIcon,
      name: t('cardManagement'),
      activeURL: '/admin/personnel/card-management',
      key: 'personnel',
      children: [
        {
          label: t('card'),
          url: 'personnel/card-management/card',
          activeURL: '/admin/personnel/card-management/card',
        },
        {
          label: t('wiegandFormat'),
          url: 'personnel/card-management/wiegand-format',
          activeURL: '/admin/personnel/card-management/wiegand-format',
        },
        {
          label: t('issuedCardRecord'),
          url: 'personnel/card-management/issued-card-record',
          activeURL: '/admin/personnel/card-management/issued-card-record',
        },
      ],
    },
    {
      icon: AccessDeviceIcon,
      rightIcon: DownArrowIcon,
      name: t('accessDevice'),
      activeURL: '/admin/accessDevice',
      key: 'accessDevice',
      children: [
        {
          label: t('device'),
          url: 'accessDevice/device',
          activeURL: '/admin/accessDevice/device',
        },
        {
          label: t('i/OBoard'),
          url: 'accessDevice/ioBoard',
          activeURL: '/admin/accessDevice/ioBoard',
        },
        {
          label: t('door'),
          url: 'accessDevice/door',
          activeURL: '/admin/accessDevice/door',
        },
        {
          label: t('reader'),
          url: 'accessDevice/reader',
          activeURL: '/admin/accessDevice/reader',
        },
        {
          label: t('auxiliaryInput'),
          url: 'accessDevice/auxiliaryInput',
          activeURL: '/admin/accessDevice/auxiliaryInput',
        },
        {
          label: t('auxiliaryOutput'),
          url: 'accessDevice/auxiliaryOutput',
          activeURL: '/admin/accessDevice/auxiliaryOutput',
        },
        {
          label: t('eventType'),
          url: 'accessDevice/eventType',
          activeURL: '/admin/accessDevice/eventType',
        },
        {
          label: t('daylightsavingtime'),
          url: 'accessDevice/daylightsavingtime',
          activeURL: '/admin/accessDevice/daylightsavingtime',
        },
        {
          label: t('realTimeMonitoring'),
          url: 'accessDevice/realtime-monitoring',
          activeURL: '/admin/accessDevice/realtime-monitoring',
        },
        {
          label: t('alarmMonitoring'),
          url: 'accessDevice/alarm-monitoring',
          activeURL: '/admin/accessDevice/alarm-monitoring',
        },
        {
          label: t('map'),
          url: 'accessDevice/map',
          activeURL: '/admin/accessDevice/map',
        },
      ],
    },
    {
      icon: AccessRuleIcon,
      rightIcon: DownArrowIcon,
      name: t('accessRule'),
      activeURL: '/admin/accessDevice/accessRule',
      key: 'accessDevice',
      children: [
        {
          label: t('timeZones'),
          url: 'accessDevice/accessRule/timeZones',
          activeURL: '/admin/accessDevice/accessRule/timeZones',
        },
        {
          label: t('holidays'),
          url: 'accessDevice/accessRule/holidays',
          activeURL: '/admin/accessDevice/accessRule/holidays',
        },
        {
          label: t('accessLevels'),
          url: 'accessDevice/accessRule/accessLevels',
          activeURL: '/admin/accessDevice/accessRule/accessLevels',
        },
        {
          label: t('setaccessByLevels'),
          url: 'accessDevice/accessRule/setaccessByLevels',
          activeURL: '/admin/accessDevice/accessRule/setaccessByLevels',
        },
        {
          label: t('setAccessByPerson'),
          url: 'accessDevice/accessRule/setAccessByPerson',
          activeURL: '/admin/accessDevice/accessRule/setAccessByPerson',
        },
        {
          label: t('setAccessByDepartment'),
          url: 'accessDevice/accessRule/setAccessByDepartment',
          activeURL: '/admin/accessDevice/accessRule/setAccessByDepartment',
        },
        {
          label: t('interlock'),
          url: 'accessDevice/accessRule/interlock',
          activeURL: '/admin/accessDevice/accessRule/interlock',
        },
        {
          label: t('linkage'),
          url: 'accessDevice/accessRule/linkage',
          activeURL: '/admin/accessDevice/accessRule/linkage',
        },
        {
          label: t('antiPassback'),
          url: 'accessDevice/accessRule/antiPassback',
          activeURL: '/admin/accessDevice/accessRule/antiPassback',
        },
        {
          label: t('firstPersonNormallyOpen'),
          url: 'accessDevice/accessRule/firstPersonNormallyOpen',
          activeURL: '/admin/accessDevice/accessRule/firstPersonNormallyOpen',
        },
        {
          label: t('multiPersonGroup'),
          url: 'accessDevice/accessRule/multi-person-group',
          activeURL: '/admin/accessDevice/accessRule/multi-person-group',
        },
        {
          label: t('multiPersonOpeningDoor'),
          url: 'accessDevice/accessRule/multi-person-opening-door',
          activeURL: '/admin/accessDevice/accessRule/multi-person-opening-door',
        },
        {
          label: t('verificationModeGroup'),
          url: 'accessDevice/accessRule/verification-mode-group',
          activeURL: '/admin/accessDevice/accessRule/verification-mode-group',
        },
        {
          label: t('parameters'),
          url: 'accessDevice/accessRule/parameters',
          activeURL: '/admin/accessDevice/accessRule/parameters',
        },
      ],
    },
  ];

  const [activeValue, setActive] = useState(slicedString);

  const openDropDown = (key: string) => {
    setActive((prev: any) => (prev === key ? '' : key));
  };

  const redirect = (url: string): void => {
    navigate(url);
  };
  // Filter the sidebar items to only include those key and split words matched
  const filterMenu = sideBarMenu.filter((menu) => menu?.key === parts?.[2]);
  useEffect(() => {
    if (slicedString) {
      setActive(slicedString);
    }
  }, [slicedString]);
  return (
    <div className="sidebar-container">
      <div className="header-logo">
        <div className="image">
          <img src={logo} alt="logo_image" />
        </div>
        <div className="image">
          <img src={logo1} alt="logo_image_1" />
        </div>
      </div>
      <div className="sidebar-content">
        <div className="sidebar-menus">
          {filterMenu.map((sidebar) => (
            <SidebarSession
              key={sidebar.name}
              redirect={redirect}
              activeValue={activeValue}
              openDropDown={openDropDown}
              menus={{
                Icon: sidebar.icon,
                RightIcon: sidebar.rightIcon,
                label: sidebar.name,
                active: sidebar.activeURL,
                children: sidebar.children,
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
