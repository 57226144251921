import { post } from './base/index';

export default {
  getDoors: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}api/door/getByPage`,
      {},
      { ...data, pageSize: 10 },
    );
  },

  cancelAlarm: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}api/door/cancelAlarm?ids=${data}`, {}),

  doorSave: (data: any = {}) => post(`${process.env.REACT_APP_API_URL}api/door/Edit`, data),

  /**
   * @param endpoint are come from the component it should be dynamic
   * @param data = id
   * @returns
   */
  doorActionsApi: (endpoint: any = '', data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}api/door/${endpoint}?ids=${data}`, {}),
  remoteOpen: (data: any = {}, interval: any = {}) =>
    post(
      `${process.env.REACT_APP_API_URL}api/door/remoteOpenById?doorId=${data}&interval=${interval}`,
      {},
    ),
  remoteClose: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}api/door/remoteCloseById?doorId=${data}`, {}),
};
