import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Button from '../../../shared-components/button/button';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';
import InputDateTime from '../../../shared-components/input-time-date/input-time-date';
import { sortValuesByStatus } from '../../../utils/helpers';

function GetTransaction({ closeModal, selectedValues }: any) {
  const { t } = useTranslation();
  const [showUpgrade, setShowUpgrade] = useState(true);
  const [value, setValue] = useState({
    transactionMode: 'newTransaction',
    validTime: [],
    startTime: null,
    endTime: null,
  });
  const options = [
    {
      label: t('getNewTransactions'),
      id: 'newTransaction',
      value: 'newTransaction',
    },
    {
      label: t('getAllTransactions'),
      id: 'allTransaction',
      value: 'allTransaction',
    },
  ];
  /**
   * @sortValuesByStatus - Helper to sort devices based on their offline/disabled status
   */
  const selectedDevices = selectedValues.sort(sortValuesByStatus);
  const submit = () => {
    console.log('hi');
  };
  const onChangeValue = (name: string, values: any) => {
    setValue((prev: any) => ({
      ...prev,
      [name]: values,
    }));
  };
  const changeDateTime = (data: any) => {
    setValue((prev: any) => ({
      ...prev,
      startTime: dayjs(data),
    }));
  };
  const changeDateEndTime = (data: any) => {
    setValue((prev: any) => ({
      ...prev,
      endTime: dayjs(data),
    }));
  };
  // Handle when the user clicks the OK button
  const handleAcceptStartTime = (data: any) => {
    setValue((prev: any) => ({
      ...prev,
      startTime: dayjs(data),
    }));
  };
  const handleAcceptEndTime = (data: any) => {
    setValue((prev: any) => ({
      ...prev,
      endTime: dayjs(data),
    }));
  };
  return (
    <div className="device-setup-popup">
      <div className="row">
        <div className="column-1">
          <h3 className="title">{t('selectedDevice')}</h3>
          <div className="box">
            {selectedDevices.map(
              (device: any) =>
                device?.status === 'online' && (
                  <RadioButtonField
                    label=""
                    name="onlineStatus"
                    alignment="column"
                    data={[
                      {
                        label: 'The devices which have disabled background verification',
                        id: 'on',
                        value: 'on',
                      },
                    ]}
                    value="on"
                    onChangeFunction={() => {}}
                  />
                ),
            )}
            {selectedDevices.filter((device: any) => device?.status === 'offline').length > 0 && (
              <div>
                <h4 className="text-warning">{t('theOfflineDevicesCanNotBeOperated')}</h4>
                <span className="text-warning">
                  {selectedDevices
                    .filter((item: any) => item?.status === 'offline')
                    .map((item: any) => item?.name)
                    .join(', ')}
                </span>
              </div>
            )}
            {selectedDevices.filter((device: any) => device?.status === 'disable').length > 0 && (
              <div>
                <h4 className="text-warning">{t('theDisabledDevicesCanNotBeOperated')}</h4>
                <span className="text-warning">
                  {selectedDevices
                    .filter((item: any) => item?.status === 'disable')
                    .map((item: any) => item?.name)
                    .join(', ')}
                </span>
              </div>
            )}
          </div>
          <RadioButtonField
            label=""
            name="transactionMode"
            alignment="row-wrap"
            data={options}
            value={value?.transactionMode}
            onChangeFunction={onChangeValue}
          />
          <CheckBoxInputField
            name="validTime"
            data={[
              {
                label: t('setValidTime'),
                id: 'yes',
                value: 'yes',
              },
            ]}
            value={value?.validTime}
            onChangeFunction={onChangeValue}
          />
          {value?.validTime?.[0] === 'yes' && (
            <div className="time-input">
              <InputDateTime
                label="start time"
                value={value?.startTime}
                onChangeFunction={changeDateTime}
                okFunction={handleAcceptStartTime}
              />
              <InputDateTime
                label="end time"
                value={value?.endTime}
                onChangeFunction={changeDateEndTime}
                okFunction={handleAcceptEndTime}
              />
            </div>
          )}
        </div>
        <div className="column-1">
          <div className="upgrading-content">
            <div className="left">
              <Button
                onClickFunction={() => setShowUpgrade(!showUpgrade)}
                title={showUpgrade ? t('hideInfo') : t('detailedInfo')}
                className="btn-primary"
              />
            </div>
            <div className="right">
              <div className="form-buttons-right">
                <Button
                  onClickFunction={submit}
                  title={t('start')}
                  className="btn-primary"
                  disabled={!selectedDevices.map((device: any) => device.status).includes('online')}
                />
                <Button
                  onClickFunction={() => {
                    closeModal();
                  }}
                  title={t('close')}
                  className="btn-default"
                />
              </div>
            </div>
          </div>
          {showUpgrade && <div className="box" />}
        </div>
      </div>
    </div>
  );
}

export default GetTransaction;
