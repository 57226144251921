import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import InputField from '../../../shared-components/input-field/input-field';
import { DoorInterface } from '../../../interface/door.interface';
import { sortValuesByStatus } from '../../../utils/helpers';
import door from '../../../utils/api/door';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';

function CommonPopup({
  closeModal,
  selectedValues,
  selectedIds,
  inputTrue,
  modelName,
}: DoorInterface) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showUpgrade, setShowUpgrade] = useState(true);
  const [doorValue, setDoorValue] = useState({
    openInterval: '5',
    userPassword: '',
  });
  const [disable, setDisable] = useState(false);
  const [successMsg, setSuccessMsg] = useState<any>({});
  const onChangeValue = (name: string, value: any) => {
    setDoorValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  /**
   * @sortValuesByStatus - Helper to sort devices based on their offline/disabled enabled
   */
  const selectedDoor = selectedValues.sort(sortValuesByStatus);

  const submit = () => {
    setDisable(true);
    if (modelName === 'remoteOpen') {
      door
        .remoteOpen(selectedIds, doorValue?.openInterval)
        .then((res) => {
          if (res) {
            setDisable(false);
            setSuccessMsg(res?.data);
          }
        })
        .catch((error) => {
          setDisable(false);
          dispatch(
            setNotificationError({
              error: error.message,
              status: error.status,
            }),
          );
        });
    } else if (modelName === 'remoteClose') {
      door
        .remoteClose(selectedIds)
        .then((res) => {
          if (res) {
            setDisable(false);
            setSuccessMsg(res?.data);
          }
        })
        .catch((error) => {
          setDisable(false);
          dispatch(
            setNotificationError({
              error: error.message,
              status: error.status,
            }),
          );
        });
    } else {
      door
        .doorActionsApi(modelName, selectedIds)
        .then((res) => {
          if (res) {
            setDisable(false);
            setSuccessMsg(res?.data);
          }
        })
        .catch((error) => {
          setDisable(false);
          dispatch(
            setNotificationError({
              error: error.message,
              status: error.status,
            }),
          );
        });
    }
  };

  useEffect(() => {
    const getOnlineDevice = selectedDoor.map((device: any) => device?.enabled).includes(true);
    if (doorValue?.userPassword && getOnlineDevice) {
      if (modelName === 'remoteOpen' && doorValue?.openInterval) {
        setDisable(false);
      } else {
        setDisable(false);
      }
    } else {
      setDisable(true);
    }
  }, [doorValue]);

  return (
    <div className="device-setup-popup">
      <div className="row">
        <div className="column-1">
          <h3 className="title">{t('selectedDevice')}</h3>
          <div className="box">
            {selectedDoor.map(
              (item: any) => item?.enabled && <span className="message">{item.name}</span>,
            )}
            {selectedDoor.filter((item: any) => !item?.enabled).length > 0 && (
              <div>
                <h4 className="text-warning">{t('theOfflineDevicesCanNotBeOperated')}</h4>
                <span className="text-warning">
                  {selectedDoor
                    .filter((item: any) => !item?.enabled)
                    .map((item: any) => item?.name)
                    .join(', ')}
                </span>
              </div>
            )}
            {selectedDoor.filter((item: any) => !item?.enabled).length > 0 && (
              <div>
                <h4 className="text-warning">{t('theDisabledDevicesCanNotBeOperated')}</h4>
                <span className="text-warning">
                  {selectedDoor
                    .filter((item: any) => !item?.enabled)
                    .map((item: any) => item?.name)
                    .join(', ')}
                </span>
              </div>
            )}
          </div>
          {inputTrue && (
            <div className="column-new">
              <div className="row row-3">
                <InputField
                  name="userPassword"
                  label={t('userPassword')}
                  id="userPassword"
                  type="password"
                  value={doorValue?.userPassword}
                  innerIconStatus={false}
                  onChangeFunction={onChangeValue}
                  required
                />
                {modelName === 'remoteOpen' && (
                  <div className="open-interval">
                    <InputField
                      name="openInterval"
                      label={t('openInterval')}
                      id="openInterval"
                      type="number"
                      value={doorValue?.openInterval}
                      innerIconStatus={false}
                      onChangeFunction={onChangeValue}
                      required
                    />
                    <div className="seconds">
                      Second <span style={{ color: '#696C6F' }}>(1-254)</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="column-1">
          <div className="upgrading-content">
            <div className="left">
              <Button
                onClickFunction={() => setShowUpgrade(!showUpgrade)}
                title={showUpgrade ? t('hideInfo') : t('detailedInfo')}
                className="btn-primary"
              />
            </div>
            <div className="right">
              <div className="form-buttons-right">
                <Button
                  onClickFunction={submit}
                  title={t('start')}
                  className="btn-primary"
                  disabled={disable}
                />
                <Button
                  onClickFunction={() => {
                    closeModal();
                  }}
                  title={t('close')}
                  className="btn-default"
                />
              </div>
            </div>
          </div>
          {showUpgrade && (
            <div className="box">
              <span className={successMsg.code === 0 ? 'message' : 'text-warning'}>
                {successMsg?.message}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CommonPopup;
