import React from 'react';
import { Cell, Label, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

function DataAnalysisChart({ data }: any) {
  const COLORS = ['#0088FE', '#F41120', '#00A76F', '#10427C'];
  const totalValue = data.reduce((a: any, b: any) => a + b.value, 0);
  // later time start this module, fix the circle to the total text
  return (
    <div>
      <ResponsiveContainer width={250} height={200}>
        <PieChart width={250} height={200}>
          <Tooltip />
          <Pie
            data={data}
            cx="50%" // Centered within the container
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={1}
            dataKey="value">
            {data.map((entry: any, index: number) => (
              <Cell key={`cell-${entry.value}`} fill={COLORS[index % COLORS.length]} />
            ))}
            <Label
              value={`Total ${totalValue}`} // Display the total value
              position="center" // Position it in the center of the pie
              style={{
                fontSize: '20px', // Customize the font size
                fontWeight: '500', // Optional: Make it bold
                fill: '#000000', // Optional: Change color of the text
              }}
            />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <div className="legends">
        {data?.map((legend: any) => (
          <div className="each-legends" key={legend?.value}>
            <div className="badge" style={{ backgroundColor: legend?.color }} />
            <span className="text">
              {legend.name} ({legend?.value})
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DataAnalysisChart;
