/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  // cards: [],
  // unIssuedCards: [],
  // status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
  // unIssuedPerson: [],
};

export const eventTypeSlice = createSlice({
  name: 'eventType',
  initialState,
  reducers: {
    // Reducer to set search filter
    setSearchFilter: (state, action: PayloadAction<any>) => {
      state.searchFilter = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter } = eventTypeSlice.actions;

export default eventTypeSlice.reducer;
