import { post } from './base/index';

export default {
  getDevices: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}api/device/getDeviceList`,
      {},
      { ...data, pageSize: 10 },
    );
  },
  getAllIps: () => post(`${process.env.REACT_APP_API_URL}api/device/getAllIPSn`, {}),
  // popup apis
  searchDevice: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}api/device/searchDev/${data}`, {}),
  /**
   * @param endpoint are come from the component it should be dynamic
   * @param data = id
   * @returns
   */
  deviceActionsApi: (endpoint: any = '', data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}api/device/${endpoint}`, {}, { data }),
  isExistAlias: (data: string) =>
    post(`${process.env.REACT_APP_API_URL}api/device/isExistAlias/${data}`, {}),
  isExistIpAddress: (data: string) =>
    post(`${process.env.REACT_APP_API_URL}api/device/isExistIpAddress/${data}`, {}),
};
