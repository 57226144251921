import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import { EditEventTypeInterface } from '../../../interface/device.interface';
import Button from '../../../shared-components/button/button';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';

function EditEventType({ closeModal, data }: EditEventTypeInterface) {
  const { t } = useTranslation();
  const [editEventType, setEditEventType] = useState(data);
  const [disable, setDisable] = useState(false);
  const onChangeValue = (name: string, value: any) => {
    setEditEventType((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };
  const submit = () => {
    console.log('hi');
  };
  useEffect(() => {
    if (editEventType?.eventLevel === 'Alarm') {
      if (editEventType?.priority) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    }
  }, [editEventType]);
  return (
    <div className="edit-eventType">
      <div className="grid-row">
        <div className="col">
          <InputField
            name="deviceName"
            label={t('deviceName')}
            id="name"
            type="text"
            value={editEventType.deviceName}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            disabled
          />
        </div>
        <div className="col">
          <InputField
            name="eventName"
            label={t('eventName')}
            id="name"
            type="text"
            value={editEventType.eventName}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            disabled
          />
        </div>
        <div className="col">
          <InputField
            name="eventNumber"
            label={t('eventNumber')}
            id="name"
            type="number"
            value={editEventType.eventNumber}
            disabled
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
          />
        </div>
        <div className="col">
          <SelectBoxField
            name="eventLevel"
            label={t('eventLevel')}
            id="eventLevel"
            value={editEventType.eventLevel}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[
              { label: t('normal'), id: 'normal' },
              { label: t('exception'), id: 'exception' },
              { label: t('alarm'), id: 'alarm' },
            ]}
            className=""
            disabled
          />
        </div>
        {editEventType?.eventLevel === 'Alarm' && (
          <div className="col">
            <SelectBoxField
              name="priority"
              label={t('priority')}
              id="priority"
              value={editEventType.priority}
              onChangeFunction={(name, value) => onChangeValue(name, value.id)}
              data={[
                { label: t('weak'), id: 'weak' },
                { label: t('medium'), id: 'medium' },
                { label: t('strong'), id: 'strong' },
                { label: t('danger'), id: 'danger' },
              ]}
              className=""
              required
            />
          </div>
        )}
        <div className="col">
          <SelectBoxField
            name="eventSound"
            label={t('eventSound')}
            id="eventSound"
            value={editEventType.eventSound}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[
              { label: t('5-2-21-2.wave'), id: 'wave' },
              { label: t('alarm'), id: 'alarm' },
            ]}
            className=""
          />
        </div>
      </div>
      <div className="switch-wrap">
        <CheckBoxInputField
          name="copyToAll"
          data={[
            {
              label: t('copyAllDevices'),
              id: 'yes',
              value: 'yes',
            },
          ]}
          value={editEventType.copyToAll}
          onChangeFunction={onChangeValue}
        />
      </div>
      <div className="form-buttons-right">
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('cancel')}
          className="btn-default btn-bold"
        />
        <Button
          onClickFunction={submit}
          title={t('ok')}
          className="btn-primary btn-bold"
          disabled={disable}
        />
      </div>
    </div>
  );
}

export default EditEventType;
