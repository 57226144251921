import React, { useEffect } from 'react';

// Use common modules
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/store';

// Use redux modules
import { closeNotification } from '../../redux/slices/NotificationSlices';

// Use assets svg icons
import { CloseIcon } from '../../assets/svgicons/svgicon';

function Notification() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { message, status, type, code } = useAppSelector((state: any) => state.notification);

  useEffect(() => {
    if (status) {
      setTimeout(() => {
        dispatch(closeNotification());
      }, 2000);
    }
    if (code === 403) {
      navigate('/login');
    }
  }, [message]);
  return (
    <div className={`notification ${status ? 'active' : ''}`}>
      <div className={`notification-content ${type}`}>
        <div className="notification-message">{message}</div>
        <div
          className="notification-close"
          onClick={() => {
            dispatch(closeNotification());
          }}
          role="presentation">
          <CloseIcon color="#f00" />
        </div>
      </div>
    </div>
  );
}

export default Notification;
