import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddDeviceInterface } from '../../../interface/accessDevice.interface';
import InputField from '../../../shared-components/input-field/input-field';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import Button from '../../../shared-components/button/button';
import DeviceSelectionModal from './deviceSelection-modal'; // Import the new modal component
import { getRS485Value, getSwitchStatesFromRS485 } from '../../../utils/helpers'; // Importing helpers
import GroupSwitchButton from '../../../shared-components/group-switch-button/group-switch-button'; // Import the GroupSwitchButton

function AddIoBoard({ closeModal }: AddDeviceInterface) {
  const { t } = useTranslation();

  // State for form fields
  const [deviceConfig, setDeviceConfig] = useState({
    name: '',
    selectDevice: '',
    protocolType: '',
    ioBoardType: '',
    rs485Address: 0,
    rs485AddressCodeFigure: {
      switch1: false,
      switch2: false,
      switch3: false,
      switch4: false,
    },
  });

  const [isDeviceSelectionOpen, setDeviceSelectionOpen] = useState(false); // State to control modal visibility

  // Handle form field changes
  const onChangeValue = (name: string, value: any) => {
    setDeviceConfig((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle RS485 Address change and update switch states accordingly
  const handleChangeRs485 = (name: string, value: number) => {
    const newValue = parseInt(value.toString(), 10);
    if (newValue > 15) {
      setDeviceConfig((prev) => ({
        ...prev,
        [name]: newValue,
      }));
      return;
    }
    const updatedSwitches = getSwitchStatesFromRS485(newValue); // Convert RS485 address to switch states
    setDeviceConfig((prev: any) => ({
      ...prev,
      rs485Address: newValue,
      rs485AddressCodeFigure: updatedSwitches,
    }));
  };

  // Handle switch toggle
  const changeSwitch = (switchName: string, value: boolean) => {
    setDeviceConfig((prevState: any) => {
      // Update the switch state
      const updatedSwitches = {
        ...prevState.rs485AddressCodeFigure,
        [switchName]: value,
      };
      // Calculate the new RS485 address value
      const newRS485Address = getRS485Value(updatedSwitches);
      // Update the state with the new switch values and RS485 address
      return {
        ...prevState,
        rs485AddressCodeFigure: updatedSwitches,
        rs485Address: newRS485Address,
      };
    });
  };

  // Submit function
  const submit = () => {
    // Handle form submission logic here
    closeModal();
  };

  // Modal control
  const openDeviceSelection = () => {
    setDeviceSelectionOpen(true);
  };

  const closeDeviceSelection = () => {
    setDeviceSelectionOpen(false);
  };

  const onSelectDevice = (device: any) => {
    setDeviceConfig((prev) => ({
      ...prev,
      selectDevice: device,
    }));
    closeDeviceSelection();
  };

  return (
    <div className="add-device-modal">
      <div className="form-row">
        <div className="name-device-protocol-row">
          <InputField
            id="name"
            name="name"
            label={t('name')}
            type="text"
            value={deviceConfig.name}
            onChangeFunction={onChangeValue}
          />
          <Button
            onClickFunction={openDeviceSelection}
            title={t('selectDevice')}
            className="btn-default"
          />
          <SelectBoxField
            id="protocolType"
            name="protocolType"
            label={t('protocolType')}
            value={deviceConfig.protocolType}
            onChangeFunction={onChangeValue}
            data={[
              { label: 'Protocol 1', id: 'protocol1' },
              { label: 'Protocol 2', id: 'protocol2' },
            ]}
          />
        </div>
      </div>

      <div className="form-row">
        <SelectBoxField
          id="ioBoardType"
          name="ioBoardType"
          label={t('ioBoardType')}
          value={deviceConfig.ioBoardType}
          onChangeFunction={onChangeValue}
          data={[
            { label: 'Type 1', id: 'type1' },
            { label: 'Type 2', id: 'type2' },
          ]}
        />
        <InputField
          id="rs485Address"
          name="rs485Address"
          label={t('rs485Address')}
          type="number"
          value={deviceConfig.rs485Address}
          onChangeFunction={(name, value) => handleChangeRs485(name, Number(value))}
        />
      </div>

      {/* GroupSwitchButton for RS485 Address Code Figure */}
      {/* Dynamic display instead of image */}
      <div className="info-message">
        <p>
          {t('rs485AddressCodeFigure')} {deviceConfig.rs485Address}
        </p>
      </div>
      <GroupSwitchButton
        label="" // Use translation for the label
        leftText={t('ON')}
        rightText={t('KE')}
        name="rs485AddressCodeFigure"
        value={deviceConfig.rs485AddressCodeFigure} // Assuming you want to bind this to rs485Address
        changeFunction={changeSwitch}
        switchCount={4}
      />
      <div className="info-message">
        <p className="warning">{t('afterTheConfigurationYouNeedToRestartTheDeviceToTakeEffect')}</p>
      </div>
      <div className="form-buttons">
        <Button onClickFunction={closeModal} title={t('Cancel')} className="btn-default" />
        <Button onClickFunction={submit} title={t('ok')} className="btn-primary" />
      </div>

      {/* Modal for selecting the device */}
      {isDeviceSelectionOpen && (
        <>
          <div
            className="modal-overlay"
            role="button"
            onClick={closeDeviceSelection}
            tabIndex={0}
            aria-label="Close Device Selection Modal"
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                closeDeviceSelection();
              }
            }}
          />
          <DeviceSelectionModal closeModal={closeDeviceSelection} onSelectDevice={onSelectDevice} />
        </>
      )}
    </div>
  );
}

export default AddIoBoard;
