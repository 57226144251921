import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { AddDayLightProps } from '../../../interface/accessDevice.interface';
import InputField from '../../../shared-components/input-field/input-field';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import Button from '../../../shared-components/button/button';
import InputDateTime from '../../../shared-components/input-time-date/input-time-date';

function AddDayLight({ closeModal, data }: AddDayLightProps) {
  const { t } = useTranslation();

  const [deviceConfig, setDeviceConfig] = useState({
    name: data?.name || '',
    selectDevice: data?.selectDevice || '',
    protocolType: data?.protocolType || '',
    startTime: data ? dayjs(data.startTime) : null,
    endTime: data ? dayjs(data.endTime) : null,
  });

  const onChangeValue = (name: string, value: any) => {
    setDeviceConfig((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const changeDateTime = (dateTime: any, name: string) => {
    if (name === 'start') {
      setDeviceConfig((prev: any) => ({
        ...prev,
        startTime: dayjs(dateTime),
      }));
    } else {
      setDeviceConfig((prev: any) => ({
        ...prev,
        endTime: dayjs(dateTime),
      }));
    }
  };

  const handleAcceptStartTime = () => {
    setDeviceConfig((prev: any) => ({
      ...prev,
      startTime: dayjs(data),
    }));
  };

  const handleAcceptEndTime = () => {
    setDeviceConfig((prev: any) => ({
      ...prev,
      endTime: dayjs(data),
    }));
  };

  const submit = () => {
    closeModal();
  };

  return (
    <div className="add-daylight">
      <div className="form-row">
        <InputField
          id="name"
          name="name"
          label={t('dstName')}
          type="text"
          value={deviceConfig.name}
          onChangeFunction={onChangeValue}
          aria-label="DST Name"
        />
        <SelectBoxField
          id="protocolType"
          name="protocolType"
          label={t('timeZone')}
          value={deviceConfig.protocolType}
          onChangeFunction={(name, value) => onChangeValue(name, value.id)}
          data={[
            { label: '(UTC-12) International Date Change Line West', id: 'UTC+01' },
            { label: '(UTC-11) Coordinated Universal Time-11', id: 'UTC+02' },
            { label: '(UTC-10) Hawaii', id: 'UTC+03' },
          ]}
          // aria-label="Time Zone"
        />
      </div>

      <div className="form-start">
        <InputDateTime
          label={t('startTime')}
          value={deviceConfig.startTime}
          onChangeFunction={(value) => changeDateTime(value, 'start')}
          okFunction={handleAcceptStartTime}
        />
        <InputDateTime
          label={t('endTime')}
          value={deviceConfig.endTime}
          onChangeFunction={(value) => changeDateTime(value, 'end')}
          okFunction={handleAcceptEndTime}
        />
      </div>

      <div className="modal-footer">
        <Button
          onClickFunction={submit}
          title={t('save&New')}
          aria-label="Save and create new entry"
          className="btn-primary"
        />
        <Button
          onClickFunction={closeModal}
          title={t('cancel')}
          aria-label="Cancel"
          className="btn-default"
        />
        <Button
          onClickFunction={submit}
          title={t('ok')}
          aria-label={data ? t('Save changes') : t('Save')}
          className="btn-primary"
        />
      </div>
    </div>
  );
}

export default AddDayLight;
