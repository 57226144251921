import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import { sortValuesByStatus } from '../../../utils/helpers';

function GetPersonalInformation({ closeModal, selectedValues }: any) {
  const { t } = useTranslation();
  const [showUpgrade, setShowUpgrade] = useState(true);
  const [personalInformation, setPersonalInformation] = useState('personnel');
  const options = [
    {
      label: t('obtainPersonnelInformation'),
      id: 'personnel',
      value: 'personnel',
    },
    {
      label: t('obtainFingerprintInformation'),
      id: 'finger',
      value: 'finger',
    },
    {
      label: t('obtainVisibleLightFaceInformation'),
      id: 'lightFace',
      value: 'lightFace',
    },
  ];
  /**
   * @sortValuesByStatus - Helper to sort devices based on their online/offline/disabled status
   * @popupErrorMessages - Helper to assign a message based on device status
   */
  const selectedDevices = selectedValues.sort(sortValuesByStatus);
  const submit = () => {
    console.log('hi');
  };
  const onChangeValue = (name: string, value: any) => {
    setPersonalInformation(value);
  };
  const obtainOptions = !selectedValues.map((device: any) => device.status).includes('online')
    ? options.filter((item: any) => item.value === 'personnel')
    : options;
  return (
    <div className="device-setup-popup">
      <div className="row">
        <div className="column-1">
          <h3 className="title">{t('selectedDevice')}</h3>
          <div className="box">
            {selectedDevices.map(
              (device: any) =>
                device?.status === 'online' && (
                  <RadioButtonField
                    label=""
                    name="onlineStatus"
                    alignment="column"
                    data={[
                      {
                        label: 'The devices which have disabled background verification',
                        id: 'on',
                        value: 'on',
                      },
                    ]}
                    value="on"
                    onChangeFunction={() => {}}
                  />
                ),
            )}
            {selectedDevices.filter((device: any) => device?.status === 'offline').length > 0 && (
              <div>
                <h4 className="text-warning">{t('theOfflineDevicesCanNotBeOperated')}</h4>
                <span className="text-warning">
                  {selectedDevices
                    .filter((item: any) => item?.status === 'offline')
                    .map((item: any) => item?.name)
                    .join(', ')}
                </span>
              </div>
            )}
            {selectedDevices.filter((device: any) => device?.status === 'disable').length > 0 && (
              <div>
                <h4 className="text-warning">{t('theDisabledDevicesCanNotBeOperated')}</h4>
                <span className="text-warning">
                  {selectedDevices
                    .filter((item: any) => item?.status === 'disable')
                    .map((item: any) => item?.name)
                    .join(', ')}
                </span>
              </div>
            )}
          </div>
          <RadioButtonField
            label=""
            name="personalInformation"
            alignment="row-wrap"
            data={obtainOptions}
            value={personalInformation}
            onChangeFunction={onChangeValue}
          />
        </div>
        <div className="column-1">
          <div className="upgrading-content">
            <div className="left">
              <Button
                onClickFunction={() => setShowUpgrade(!showUpgrade)}
                title={showUpgrade ? t('hideInfo') : t('detailedInfo')}
                className="btn-primary"
              />
            </div>
            <div className="right">
              <div className="form-buttons-right">
                <Button
                  onClickFunction={submit}
                  title={t('start')}
                  className="btn-primary"
                  disabled={!selectedDevices.map((device: any) => device.status).includes('online')}
                />
                <Button
                  onClickFunction={() => {
                    closeModal();
                  }}
                  title={t('close')}
                  className="btn-default"
                />
              </div>
            </div>
          </div>
          {showUpgrade && <div className="box" />}
        </div>
      </div>
    </div>
  );
}

export default GetPersonalInformation;
