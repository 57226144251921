import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import SearchIcon, { RightArrowIcon } from '../../../assets/svgicons/svgicon';
import Table from '../../../shared-components/table/table';
import Button from '../../../shared-components/button/button';
import { DeviceProps, SelectedDeviceProps } from '../../../interface/accessByDepartment';

function SelectLinkage({
  searchValue = '',
  changeSearch,
  tableColumns,
  getSelected,
  closeModal = () => {},
}: SelectedDeviceProps) {
  const { t } = useTranslation();
  const tableData = [
    {
      id: '0',
      deviceName: 'Test - 1',
      serialNumber: 'CJPM230660002',
      ownedDevice: 'CJPM230660002',
    },
    {
      id: '1',
      deviceName: 'Test - 2',
      serialNumber: 'CJPM230660002',
      ownedDevice: 'CJPM230660002',
    },
  ];
  const [availableData, setAvailableData] = useState<DeviceProps[]>(tableData);
  const [selectedData, setSelectedData] = useState<DeviceProps[]>([]);
  // State to manage selected items in both tables
  const [selectRow, setSelectRow] = useState<string | null>(null); // Track selected items in "available" table
  const [selectedTableRow, setSelectedTableRow] = useState<string | null>(null);

  // Handle moving a single item from available to selected
  const handleSelectTable = () => {
    if (selectRow) {
      const newSelected = availableData.find((item: any) => item.id === selectRow);
      const remainingData = availableData.filter((item: any) => item.id !== selectRow);

      if (newSelected) {
        setAvailableData([...remainingData, ...selectedData]);
        setSelectedData([newSelected]);
        setSelectRow(null); // Clear selection
      }
    }
  };

  // Handle moving a single item from selected back to available
  const handleSelectSelectedTable = () => {
    if (selectedTableRow) {
      const unselected = selectedData.find((item: any) => item.id === selectedTableRow);
      const remainingSelected = selectedData.filter((item: any) => item.id !== selectedTableRow);

      if (unselected) {
        setAvailableData([...availableData, unselected]);
        setSelectedData(remainingSelected);
        setSelectedTableRow(null); // Clear selection
      }
    }
  };
  const submit = () => {
    if (selectedData.length > 0) {
      getSelected(selectedData);
      closeModal();
    }
  };
  return (
    <div className="flex-row column" style={{ width: '66vw' }}>
      <div className="swap-table">
        <div className="search-input">
          <InputField
            name="name"
            label={t('search')}
            id="name"
            type="text"
            value={searchValue}
            innerIconStatus
            InnerIcon={SearchIcon}
            onChangeFunction={changeSearch}
          />
        </div>
        <div className="table-container">
          <div className="table-wrapper">
            <h4 className="title">{t('alternative')}</h4>
            <Table
              classes="bordered"
              header={tableColumns}
              value={availableData}
              radioStatus
              selectedValues={selectRow ? [selectRow] : []}
              onSelect={(selected) => setSelectRow(selected[0])}
            />
          </div>
          <div className="icons-wrapper">
            <div
              className={`icon ${!selectRow ? 'disabled' : ''}`}
              role="none"
              onClick={handleSelectTable}>
              <RightArrowIcon />
            </div>
            <div
              className={`icon backward ${!selectedTableRow ? 'disabled' : ''}`}
              role="none"
              onClick={handleSelectSelectedTable}>
              <RightArrowIcon />
            </div>
          </div>
          <div className="table-wrapper">
            <h4 className="title">
              {t('selected')} ({selectedData.length})
            </h4>
            <Table
              classes="bordered"
              header={tableColumns}
              value={selectedData}
              radioStatus
              selectedValues={selectedTableRow ? [selectedTableRow] : []} // Use an array with a single selected row
              onSelect={(selected) => setSelectedTableRow(selected[0])} // Update single selection
            />
          </div>
        </div>
      </div>
      <div className="form-buttons-right">
        <Button onClickFunction={closeModal} title={t('cancel')} className="btn-default" />
        <Button onClickFunction={submit} title={t('done')} className="btn-primary" />
      </div>
    </div>
  );
}

export default SelectLinkage;
