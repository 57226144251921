import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import InputField from '../../../shared-components/input-field/input-field';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field'; // For dropdown
import { EditAuxiliaryProps } from '../../../interface/accessAuxiliaryInput';
import InputTextAreaField from '../../../shared-components/input-text-area-field/input-text-area-field';

function EditAuxiliary({ initialData, closeModal, saveChanges }: EditAuxiliaryProps) {
  const { t } = useTranslation();
  const [editData, setEditData] = useState(initialData);

  // Handles changes to the form fields
  const onChange = (name: string, value: any) => {
    setEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="edit-reader-modal">
      <form className="edit-reader-form">
        <div className="input-grid">
          <div className="input-form">
            <div className="input-item">
              <InputField
                id="deviceName"
                value={editData.deviceName}
                label={t('deviceName')}
                type="text"
                name="deviceName"
                readonly
              />
            </div>
            <div className="input-item">
              <InputField
                id="number"
                value={editData.number}
                label={t('number')}
                type="number"
                name="number"
                readonly
              />
            </div>
            <div className="input-item">
              <InputField
                id="printedName"
                value={editData.printedName}
                label={t('printedName')}
                type="text"
                name="printedName"
                readonly
              />
            </div>
          </div>
          <div className="input-select">
            <div className="input-item">
              <SelectBoxField
                id="activeTimeZone"
                label={t('activeTimeZone')}
                value={editData.activeTimeZone}
                name="activeTimeZone"
                data={[
                  { label: '24-Hour Accessible', id: '24-hour' },
                  { label: 'Restricted Hours', id: 'restricted' },
                ]}
                onChangeFunction={(name, value) => onChange(name, value)}
              />
            </div>
            <div className="input-item">
              <InputField
                id="name"
                value={editData.name}
                label={t('name')}
                type="text"
                name="name"
                onChangeFunction={(name, value) => onChange(name, value)}
              />
            </div>
            <div className="input-item">
              <InputTextAreaField
                id="remarks"
                value={editData.remarks || ''} // Provide a fallback value
                label={t('remarks')}
                name="remarks"
                onChangeFunction={(name, value) => onChange(name, value)}
              />
            </div>
          </div>
        </div>
        <div className="form-buttons-right">
          <Button
            onClickFunction={() => closeModal()}
            title={t('cancel')}
            className="btn-default btn-bold"
          />
          <Button
            onClickFunction={() => {
              saveChanges(editData);
              closeModal();
            }}
            title={t('ok')}
            className="btn-primary btn-bold"
          />
        </div>
      </form>
    </div>
  );
}

export default EditAuxiliary;
