import React, { useState, useRef, useEffect } from 'react';

type TimeStateProps = {
  hour: string;
  minute: string;
};

interface TimeInputProps {
  value: TimeStateProps;
  onChange: (time: TimeStateProps) => void;
  hourName?: string;
  minuteName?: string;
}

function TimeInput({ value, onChange, hourName, minuteName }: TimeInputProps) {
  const [time, setTime] = useState<TimeStateProps>(value);
  const hourRef = useRef<HTMLInputElement>(null);
  const minuteRef = useRef<HTMLInputElement>(null);
  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>, type: 'hour' | 'minute') => {
    const newValue = e.target.value;

    if (newValue.length <= 2 && /^[0-9]*$/.test(newValue)) {
      const newTime = { ...time, [type]: e.target.value };
      setTime(newTime);
      onChange(newTime);

      if (newValue.length === 2) {
        if (type === 'hour' && hourRef.current) {
          minuteRef.current?.focus();
          minuteRef.current?.select();
        }
      }
    }
  };
  useEffect(() => {
    setTime(value);
  }, [value]);

  return (
    <div className="time-input-field">
      <input
        name={hourName}
        id={hourName}
        ref={hourRef}
        type="text"
        value={time.hour}
        maxLength={2}
        onChange={(e) => handleTimeChange(e, 'hour')}
        onClick={() => hourRef.current?.select()}
        placeholder="HH"
        className="input_field"
      />
      <span className="mediator"> : </span>
      <input
        name={minuteName}
        id={minuteName}
        ref={minuteRef}
        type="text"
        value={time.minute}
        maxLength={2}
        onChange={(e) => handleTimeChange(e, 'minute')}
        onClick={() => minuteRef.current?.select()}
        placeholder="MM"
        className="input_field"
      />
    </div>
  );
}

TimeInput.defaultProps = {
  hourName: '',
  minuteName: '',
};

export default TimeInput;
