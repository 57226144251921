import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import { Search } from '../../../assets/svgicons/svgicon';
import CardStatus from '../../../shared-components/card-status/cardStatus';
import CardRightButtonGroup from '../../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import RealTimeCommonPopup from './realTime-common-popup';

function AuxiliaryOutput() {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [selectedCards, setSelectedCards] = useState<number[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });

  const header = [
    {
      label: t('status'),
      key: 'status',
    },
    {
      label: t('device'),
      key: 'device',
    },
    {
      label: t('number'),
      key: 'number',
    },
  ];

  const tempDoorDatas = [
    {
      title: 'test - 1',
      status: 'Online',
      device: '678909876',
      number: '1',
    },
    {
      title: 'test - 1',
      status: 'Offline',
      device: '678909876',
      number: '1',
    },
    {
      title: 'test - 1',
      status: 'Unknown',
      device: '678909876',
      number: '1',
    },
    {
      title: 'test - 1',
      status: 'Online',
      device: '678909876',
      number: '1',
    },
    {
      title: 'test - 1',
      status: 'Disable',
      device: '678909876',
      number: '1',
    },
  ];
  const statusData = [
    {
      title: t('online'),
      count: tempDoorDatas.filter((item) => item.status === 'Online')?.length || 0,
      color: 'success',
    },
    {
      title: t('offline'),
      count: tempDoorDatas.filter((item) => item.status === 'Offline')?.length || 0,
      color: 'danger',
    },
    {
      title: t('disable'),
      count: tempDoorDatas.filter((item) => item.status === 'Disable')?.length || 0,
      color: 'warning',
    },
    {
      title: t('unknown'),
      count: tempDoorDatas.filter((item) => item.status === 'Unknown')?.length || 0,
      color: 'secondary',
    },
    {
      title: t('currentTotal'),
      count: tempDoorDatas?.length || 0,
    },
  ];

  const moreActionButton: any = [
    {
      title: t('remoteOpening'),
      clickFunction: (): void => {
        setModalView({
          title: t('remoteOpening'),
          content: (
            <RealTimeCommonPopup
              modelName="remoteOpening"
              selected={selectedCards}
              closeModal={() => setModalOpen(false)}
            />
          ),
        });
        setModalOpen(true);
      },
    },
    {
      title: t('remoteClosing'),
      clickFunction: (): void => {
        setModalView({
          title: t('remoteClosing'),
          content: (
            <RealTimeCommonPopup
              modelName="remoteClosing"
              selected={selectedCards}
              closeModal={() => setModalOpen(false)}
              message={t('areYouWantToPerformTheRemoteClosingOperation')}
            />
          ),
        });
        setModalOpen(true);
      },
    },
    {
      title: t('remoteNormallyOpen'),
      clickFunction: (): void => {
        setModalView({
          title: t('remoteNormallyOpen'),
          content: (
            <RealTimeCommonPopup
              modelName="remoteNormallyOpen"
              selected={selectedCards}
              closeModal={() => setModalOpen(false)}
              message={t('areYouWantToPerformTheRemoteNormallyOpenOperation')}
            />
          ),
        });
        setModalOpen(true);
      },
    },
  ];

  const handleCardSelect = (index: string) => {
    setSelectedCards(
      (prevSelected: any) =>
        prevSelected.includes(index)
          ? prevSelected.filter((item: any) => item !== index) // Remove if already selected
          : [...prevSelected, index], // Add to selected list if not selected
    );
  };
  return (
    <div className="rtm-door">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="search-row">
        <div className="search-input">
          <InputField
            name="search"
            label={t('search')}
            id="name"
            type="text"
            value={search}
            innerIconStatus
            InnerIcon={Search}
            onChangeFunction={(name: string, value: any) => setSearch(value)}
          />
        </div>
        <CardRightButtonGroup
          actions={moreActionButton}
          buttonIcon={false}
          buttonLabel=""
          buttonClass=""
          buttonStatus={false}
          buttonFunction={() => {}}
        />
      </div>
      <div className="card-grid">
        {tempDoorDatas.map((item, index) => (
          <CardStatus
            key={item.title}
            data={item}
            header={header}
            index={index}
            onSelect={handleCardSelect}
            isSelected={selectedCards.includes(index)}
          />
        ))}
      </div>
      <div className="status-container">
        {statusData.map((status: any) => (
          <div className="status-wrap">
            {status?.color && <div className={`circle-dot ${status?.color}`} />}
            <span style={{ color: '#696C6F' }}>
              {status?.title} : {status?.count}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AuxiliaryOutput;
