import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import Button from '../../../shared-components/button/button';

function RealTimeCommonPopup({ selected, closeModal, modelName, message }: any) {
  const { t } = useTranslation();
  const [door, setDoor] = useState('5');
  const submit = () => {
    console.log('hi');
  };
  return (
    <div className="door-modals">
      <div className="flex-row column">
        <h4 className="label">
          {t('target')} : <span>{selected.length > 0 ? t('selected') : t('allDoors')}</span>
        </h4>
        {modelName === 'remoteOpening' ? (
          <div className="input-wrap">
            <InputField
              name="door"
              label={t('door')}
              id="name"
              type="text"
              value={door}
              innerIconStatus={false}
              onChangeFunction={(name: string, value: any) => setDoor(value)}
              validation
              message={t('second(1-254)')}
            />
          </div>
        ) : (
          <span className="message">{message}</span>
        )}
        <div className="form-buttons-right">
          <Button
            onClickFunction={() => {
              closeModal();
            }}
            title={t('cancel')}
            className="btn-default"
          />
          <Button
            onClickFunction={submit}
            title={t('ok')}
            className="btn-primary"
            disabled={!door}
          />
        </div>
      </div>
    </div>
  );
}

export default RealTimeCommonPopup;
