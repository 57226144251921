import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import shared components
import { useAppSelector } from '../../redux/store';
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import ModalBox from '../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import { ExportIcon } from '../../assets/svgicons/svgicon';
import AccessByLevelExport from './components/export-data';
import AddPersonnel from './components/add-personnel';
// import AddHolidays from './components/add-holidays';

function SetAccessByLevels() {
  const { t } = useTranslation();
  const { totalDataCount, searchFilter } = useAppSelector((state: any) => state.cards);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [selectedFullData, setSelectedFullData] = useState<string[]>([]);
  console.log(selectedFullData); // use after api integration
  const [personnelSelect, setPersonnelSelect] = useState<string[]>([]);
  // let handleEditOpen: any;
  const columnsField = [
    {
      accessorKey: 'name',
      header: t('levelName'),
    },
    {
      accessorKey: 'areaName',
      header: t('areaName'),
    },
    {
      accessorKey: 'timeZone',
      header: t('timeZone'),
    },
  ];
  const personnelColumn = [
    {
      accessorKey: 'id',
      header: t('personnelId'),
    },
    {
      accessorKey: 'firstName',
      header: t('firstName'),
    },
    {
      accessorKey: 'lastName',
      header: t('lastName'),
    },
    {
      accessorKey: 'department',
      header: t('department'),
    },
  ];
  const handleEditOpen = (editData: any) => {
    console.log(editData);
    if (!modalOpen) {
      setModalOpen(true);
      setModalView({
        title: t('addPersonnel'),
        content: <AddPersonnel closeModal={() => setModalOpen(false)} />,
      });
    }
  };
  const getFilterData = () => {
    return {};
  };

  const fetchNextPage = () => {
    return {};
  };
  const exportData = (data: any) => {
    console.log(data);
    setModalOpen(false);
  };
  const actionButtons = [
    {
      label: t('addPersonnel'),
      function: (val: any) => handleEditOpen(val),
    },
  ];
  const handleExport = () => {
    setModalOpen(true);
    setModalView({
      title: t('exportPersonnelOfAccessLevel'),
      content: (
        <AccessByLevelExport closeModal={() => setModalOpen(false)} submitFunction={exportData} />
      ),
    });
  };
  const tempData = [
    {
      id: '0',
      name: 'Free Rotation',
      areaName: 'ad',
      timeZone: '2 to 10',
    },
    {
      id: '1',
      name: 'Free Rotation',
      areaName: 'ad',
      timeZone: '2 to 10',
    },
    {
      id: '2',
      name: 'Free Rotation',
      areaName: 'ad',
      timeZone: '2 to 10',
    },
  ];
  return (
    <div className="access-by-level">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessDevice'),
          },
          {
            title: t('setaccessByLevels'),
          },
        ]}
      />
      <CardBox
        header={t('accessLevels')}
        title={t('listOfAccessLevels')}
        rightSide={
          <CardRightButtonGroup
            buttonLabel={t('new')}
            buttonClass="btn-primary btn-sm"
            buttonStatus={false}
            buttonFunction={(): void => {
              setModalOpen(true);
              setModalView({
                title: t('new'),
                content: <>hi</>,
              });
            }}
          />
        }>
        <div className="action-wrapper">
          <div className="action-btn" role="presentation" onClick={handleExport}>
            <ExportIcon />
            <span className="label">{t('exportPersonnelOfAccessLevel')}</span>
          </div>
        </div>
        <DataTable
          actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => setSelectedValues(val)}
          getSelectedFullData={(val: any): any => setSelectedFullData(val)}
          checkBoxActive
          fetchNextPage={fetchNextPage}
          // isFetching={status === 'loading'} // Placeholder status
          values={tempData} // Use the actual data here
          fixedColumn={['name']}
          totalRecords={totalDataCount}
          height={411}
        />
        <hr className="line" />
        <div className="browse-table">
          <div className="header">
            <h4>Browse Personnel ZKTeco SG (ZKTeco SG) From Levels</h4>
            <span className="sub-title">{t('listOfPersonnel')}</span>
          </div>
          <DataTable
            actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
            action={actionButtons}
            columns={personnelColumn}
            getFilterData={getFilterData}
            assignFilterValue={searchFilter}
            assignSelectedData={personnelSelect}
            getSelectedData={(val: any): any => setPersonnelSelect(val)}
            // getSelectedFullData={(val: any): any => setSelectedData(val)}
            checkBoxActive
            fetchNextPage={fetchNextPage}
            // isFetching={status === 'loading'} // Placeholder status
            values={[]} // Use the actual data here
            fixedColumn={['id']}
            totalRecords={0}
            height={411}
          />
        </div>
      </CardBox>
    </div>
  );
}

export default SetAccessByLevels;
