import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import InputField from '../../../shared-components/input-field/input-field';
import { DoorInterface } from '../../../interface/door.interface';
import { sortValuesByStatus } from '../../../utils/helpers';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';

function CommonPopup({ closeModal, selectedValues, inputTrue, modelName }: DoorInterface) {
  const { t } = useTranslation();
  const [showUpgrade, setShowUpgrade] = useState(true);
  const [doorValue, setDoorValue] = useState({
    openInterval: '5',
    userPassword: '',
  });
  const [disable, setDisable] = useState(false);
  const onChangeValue = (name: string, value: any) => {
    setDoorValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const selectedData = selectedValues.sort(sortValuesByStatus).map((item: any) => ({
    ...item,
    status: item.enable,
  }));
  const groupedData = selectedData
    .filter((item: any) => item?.enable === 'disable')
    .reduce((result: any, item: any) => {
      // Create a copy of the result object to avoid mutating the original parameter
      const newResult = { ...result };
      // If the deviceName doesn't exist in the result, create a new array
      if (!newResult[item.deviceName]) {
        newResult[item.deviceName] = [];
      }
      // Push the reader's name into the array for that deviceName
      newResult[item.deviceName].push(item.name);
      return newResult;
    }, {});

  const submit = () => {
    if (modelName === 'remoteOpen') {
      console.log('hi');
    } else if (modelName === 'remoteClose') {
      console.log('hi');
    }
  };

  useEffect(() => {
    const getOnlineDevice = selectedData.map((device: any) => device?.enable).includes('online');
    if (doorValue?.userPassword && getOnlineDevice) {
      if (modelName === 'remoteOpen' && doorValue?.openInterval) {
        setDisable(false);
      } else if (doorValue?.openInterval) {
        setDisable(false);
      }
    } else {
      setDisable(true);
    }
  }, [doorValue]);

  return (
    <div className="device-setup-popup">
      <div className="row">
        <div className="column-1">
          <h3 className="title">{t('selectedAuxiliaryOutput')}</h3>
          <div className="box">
            {selectedData.map(
              (item: any) =>
                item?.enable === 'online' && (
                  <RadioButtonField
                    label=""
                    name="onlineStatus"
                    alignment="column"
                    data={[
                      {
                        label: t('theDevicesWhichHaveDisabledBackgroundVerification'),
                        id: 'on',
                        value: 'on',
                      },
                    ]}
                    value="on"
                    onChangeFunction={() => {}}
                  />
                ),
            )}
            {selectedData.filter((item: any) => item?.enable === 'disable').length > 0 && (
              <div>
                <h4 className="text-warning">{t('theDisabledAuxiliaryOutputCannotBeOperated')}</h4>
                {Object.keys(groupedData).map((deviceName) => (
                  <div className="text-warning">
                    <span>{deviceName} = </span>
                    <span>{groupedData[deviceName].join(', ')}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
          {inputTrue && (
            <div className="column-new">
              <div className="row row-3">
                <InputField
                  name="userPassword"
                  label={t('userPassword')}
                  id="userPassword"
                  type="password"
                  value={doorValue?.userPassword}
                  innerIconStatus={false}
                  onChangeFunction={onChangeValue}
                  required
                />
                {modelName === 'remoteOpen' && (
                  <div className="open-interval">
                    <InputField
                      name="openInterval"
                      label={t('openInterval')}
                      id="openInterval"
                      type="number"
                      value={doorValue?.openInterval}
                      innerIconStatus={false}
                      onChangeFunction={onChangeValue}
                      required
                    />
                    <div className="seconds">
                      Second <span style={{ color: '#696C6F' }}>(1-254)</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="column-1">
          <div className="upgrading-content">
            <div className="left">
              <Button
                onClickFunction={() => setShowUpgrade(!showUpgrade)}
                title={showUpgrade ? t('hideInfo') : t('detailedInfo')}
                className="btn-primary"
              />
            </div>
            <div className="right">
              <div className="form-buttons-right">
                <Button
                  onClickFunction={submit}
                  title={t('start')}
                  className="btn-primary"
                  disabled={disable}
                />
                <Button
                  onClickFunction={() => {
                    closeModal();
                  }}
                  title={t('close')}
                  className="btn-default"
                />
              </div>
            </div>
          </div>
          {showUpgrade && <div className="box" />}
        </div>
      </div>
    </div>
  );
}

export default CommonPopup;
