// Use common modules
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../redux/store';
import AddIoBoard from './components/add-ioboard';
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../shared-components/modal-box/modal-box';
import { AddIcon } from '../../assets/svgicons/svgicon';

function IOBoard() {
  const { t } = useTranslation();
  const { totalDataCount, searchFilter } = useAppSelector((state: any) => state.cards);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('i/OBoard'),
    content: <>hi</>,
  });
  const [selectedCards, setSelectedCards] = useState<string[]>([]);

  const columnsField = [
    {
      accessorKey: 'name',
      header: t('name'),
    },
    {
      accessorKey: 'areaName',
      header: t('areaName'),
    },
    {
      accessorKey: 'ownedDevice',
      header: t('ownedDevice'),
    },
    {
      accessorKey: 'number',
      header: t('number'),
    },
    {
      accessorKey: 'i/o Board',
      header: t('i/o Board'),
    },
    {
      accessorKey: 'address',
      header: t('rs485Address'),
    },
    {
      accessorKey: 'protocolType',
      header: t('protocolType'),
    },
    {
      accessorKey: 'operations',
      header: t('operations'),
    },
  ];
  const getFilterData = () => {
    return {};
  };

  const fetchNextPage = () => {
    return {};
  };
  return (
    <div className="card-management">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessDevice'),
          },
          {
            title: t('i/OBoard'),
          },
        ]}
      />
      <CardBox
        title={t('listOfi/OBoard')}
        rightSide={
          <CardRightButtonGroup
            buttonIcon={<AddIcon color="#ffffff" />}
            buttonLabel={t('new')}
            buttonClass="btn-primary btn-sm"
            buttonStatus
            buttonFunction={(): void => {
              setModalOpen(true);
              setModalView({
                title: t('getPersonnelInformation'),
                content: <AddIoBoard closeModal={() => setModalOpen(false)} />,
              });
            }}
          />
        }>
        <DataTable
          actionButtonStatus={false}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedCards}
          getSelectedData={(val: any): any => setSelectedCards(val)}
          checkBoxActive
          fetchNextPage={fetchNextPage}
          values={[]}
          fixedColumn={['cardNo']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default IOBoard;
