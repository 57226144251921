import React, { useEffect, useState } from 'react';

// Use interface
import { SelectBoxFieldInterFace, SelectItemInterFace } from '../../interface/common.interface';

// Use assets svg icons
import { DownArrowIcon, CloseIcon } from '../../assets/svgicons/svgicon';

function SelectBoxField({
  name,
  label,
  id,
  data,
  value,
  className,
  disabled,
  required,
  message,
  errorStatus,
  validation,
  onChangeFunction,
}: SelectBoxFieldInterFace) {
  const [selectValue, setSelectValue] = useState<string>('');
  const [selectItems, setSelectItems] = useState(data);

  const onKeyup = (e: any): void => {
    setSelectValue(e.target.value);
    setSelectItems(
      data.filter((val) => val.label.toLowerCase().includes(e.target.value.toLowerCase())),
    );
  };
  const selectItem = (item: SelectItemInterFace): void => {
    onChangeFunction(name, item);
    setSelectValue('');
    setSelectItems(data);
  };
  const onBlurInput = (): void => {
    const selectData = data.find((val) => val.id === value);
    setSelectValue(selectData?.label || '');
    setTimeout(() => {
      setSelectItems(data);
    }, 1000);
  };
  const closeData = (): void => {
    onChangeFunction(name, { id: '', label: '' });
    setSelectValue('');
    setSelectItems(data);
  };
  useEffect((): void => {
    const selectData = data.find((val) => val.id === value);
    setSelectValue(selectData?.label || '');
  }, [value]);

  useEffect(() => {
    setSelectItems(data);
    const selectData = data.find((val) => val.id === value);
    setSelectValue(selectData?.label || '');
  }, [data]);
  return (
    <div className="form-select-item">
      <input
        type="text"
        id={id}
        className={`input_field ${className}`}
        value={selectValue}
        onBlur={onBlurInput}
        autoComplete="off"
        required
        name={name}
        onChange={onKeyup}
        disabled={disabled}
      />
      <label className={`input-label ${className}`} htmlFor={id} title={label}>
        {label}
        {required && <span>*</span>}
      </label>
      <div className="input-right-close-icon" onClick={closeData} role="presentation">
        <CloseIcon />
      </div>
      <div className="input-right-icon">
        <DownArrowIcon />
      </div>
      <div className="select-items">
        {selectItems.map((item) => (
          <div
            key={item.id}
            className="select-item"
            onClick={() => selectItem(item)}
            role="presentation">
            {item.label}
          </div>
        ))}
        {selectItems.length === 0 && <div className="select-item disabled">No Data Found</div>}
      </div>
      {errorStatus && <small className="input-error-message">{message}</small>}
      {validation && <small className="input-validation-message">{message}</small>}
    </div>
  );
}

export default SelectBoxField;
