import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddDeviceInterface } from '../../../interface/accessDevice.interface';
import InputField from '../../../shared-components/input-field/input-field';
import { isValidIpAddress } from '../../../shared-components/form/validation';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';
import Button from '../../../shared-components/button/button';
import { ClearDataIcon } from '../../../assets/svgicons/svgicon';
import GroupSwitchButton from '../../../shared-components/group-switch-button/group-switch-button';
import { getRS485Value, getSwitchStatesFromRS485 } from '../../../utils/helpers';
import InputIpAddressField from '../../../shared-components/input-ip-address-field/input-ip-address-field';
import device from '../../../utils/api/device';

function AddDevice({ closeModal, data }: AddDeviceInterface) {
  const { t } = useTranslation();
  const [addDevice, setAddDevice] = useState({
    name: '',
    communicationType: 'tcp/ip',
    ipAddress: '',
    communicationPort: '',
    communicationPassword: '',
    iconType: '',
    controlPanelType: '',
    area: '',
    addToLevel: '',
    clearData: [],
    serialPortNumber: '',
    rs485Address: 0,
    baudRate: '',
    rs485AddressCodeFigure: {
      switch1: false,
      switch2: false,
      switch3: false,
      switch4: false,
      switch5: false,
      switch6: false,
      switch7: false,
      switch8: false,
    },
  });
  const [disable, setDisable] = useState(false);
  const [checkExistName, setCheckExistName] = useState(true);
  const [checkExistIp, setCheckExistIp] = useState(true);
  const onChangeValue = (name: string, value: any) => {
    setAddDevice((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  console.log(addDevice);
  const changeSwitch = (switchName: string, value: boolean) => {
    setAddDevice((prevState: any) => {
      // Update the switch state
      const updatedSwitches = {
        ...prevState.rs485AddressCodeFigure,
        [switchName]: value,
      };
      // Calculate the new RS485 address value
      const newRS485Address = getRS485Value(updatedSwitches);
      // Update the state with the new switch values and RS485 address
      return {
        ...prevState,
        rs485AddressCodeFigure: updatedSwitches,
        rs485Address: newRS485Address,
      };
    });
  };

  const handleChangeRs485 = (name: string, value: any) => {
    const newValue = parseInt(value, 10); // Convert input to number or default to 0
    if (newValue > 63) {
      setAddDevice((prevState: any) => ({
        ...prevState,
        rs485Address: newValue,
      }));
      return;
    }
    const updatedSwitches = getSwitchStatesFromRS485(newValue);
    setAddDevice((prevState: any) => ({
      ...prevState,
      rs485Address: newValue,
      rs485AddressCodeFigure: updatedSwitches, // Update switch states based on input value
    }));
  };
  const submit = () => {
    console.log('hi');
  };

  const onBlur = async (name: string, value: any) => {
    if (name === 'name') {
      const responseData = await device.isExistAlias(value);
      setCheckExistName(responseData?.data?.data);
    }
  };

  const onBlurIpAddress = async () => {
    if (addDevice.ipAddress) {
      const responseData = await device.isExistIpAddress(addDevice.ipAddress);
      setCheckExistIp(responseData.data.data);
    }
  };

  useEffect(() => {
    // Ensure IP address is not an empty string
    // Check if the IP address is in valid format
    const isIpAddressNonEmpty = addDevice?.ipAddress?.trim() ?? '';
    if (
      addDevice?.name &&
      addDevice?.iconType &&
      addDevice?.area &&
      !checkExistName &&
      !checkExistIp
    ) {
      if (
        addDevice?.communicationType === 'tcp/ip' &&
        addDevice?.communicationPort &&
        isIpAddressNonEmpty &&
        isValidIpAddress(isIpAddressNonEmpty)
      ) {
        setDisable(false);
      } else if (
        addDevice?.communicationType === 'rs485' &&
        addDevice?.serialPortNumber &&
        addDevice?.rs485Address &&
        addDevice?.baudRate
      ) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    } else {
      setDisable(true);
    }
  }, [addDevice, checkExistName, checkExistIp]);
  return (
    <div className="add-device">
      <div className="add-device-form">
        <div className="single-row">
          <InputField
            name="name"
            label={t('deviceName')}
            id="name"
            type="text"
            value={addDevice.name}
            required
            errorStatus={!checkExistName}
            message={t('nameIsAlreadyExist')}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            onBlurFunction={onBlur}
          />
          <RadioButtonField
            label={t('communicationType')}
            name="communicationType"
            alignment="column"
            required
            data={
              data
                ? [
                    { label: t('tct/ip'), id: 'tcp-ip', value: 'tcp/ip', disabled: 'disabled' },
                    { label: t('rs485'), id: 'rs485', value: 'rs485', disabled: 'disabled' },
                    { label: t('http'), id: 'http', value: 'http', disabled: 'disabled' },
                  ]
                : [
                    { label: t('tct/ip'), id: 'tcp-ip', value: 'tcp/ip' },
                    { label: t('rs485'), id: 'rs485', value: 'rs485' },
                  ]
            }
            value={addDevice.communicationType}
            onChangeFunction={onChangeValue}
          />
        </div>
        {addDevice.communicationType === 'tcp/ip' && (
          <div className="single-row-1">
            <InputIpAddressField
              name="ipAddress"
              label={t('ipAddress')}
              id="ipAddress"
              type="text"
              value={addDevice.ipAddress}
              onChangeFunction={onChangeValue}
              onBlurFunction={onBlurIpAddress}
              errorStatus={!checkExistIp}
              message={t('objectIsAlreadyExist')}
              required
            />
            <InputField
              name="communicationPort"
              label={t('communicationPort')}
              id="communicationPort"
              type="text"
              value={addDevice.communicationPort}
              innerIconStatus={false}
              onChangeFunction={onChangeValue}
              required
            />
            <InputField
              name="communicationPassword"
              label={t('communicationPassword')}
              id="communicationPassword"
              type="text"
              value={addDevice.communicationPassword}
              innerIconStatus={false}
              onChangeFunction={onChangeValue}
            />
          </div>
        )}
        {addDevice.communicationType === 'rs485' && (
          <div className="grid-row">
            <div className="col-8">
              <div className="single-row">
                <SelectBoxField
                  name="serialPortNumber"
                  label={t('serialPortNumber')}
                  id="serialPortNumber"
                  value={addDevice.serialPortNumber}
                  onChangeFunction={(name, value) => onChangeValue(name, value.id)}
                  data={[
                    { label: t('excel'), id: 'XLS' },
                    { label: t('pdf'), id: 'PDF' },
                    { label: t('csv'), id: 'CSV' },
                  ]}
                  className=""
                  required
                />
                <InputField
                  name="rs485Address"
                  label={t('rs485Address')}
                  id="rs485Address"
                  type="number"
                  value={addDevice.rs485Address}
                  innerIconStatus={false}
                  onChangeFunction={handleChangeRs485}
                  errorStatus={addDevice?.rs485Address > 63}
                  message="Please enter a value between 1 and 63."
                  required
                />
              </div>
              <div className="single-row">
                <SelectBoxField
                  name="baudRate"
                  label={t('baudRate')}
                  id="baudRate"
                  value={addDevice.baudRate}
                  onChangeFunction={(name, value) => onChangeValue(name, value.id)}
                  data={[
                    { label: t('excel'), id: 'XLS' },
                    { label: t('pdf'), id: 'PDF' },
                    { label: t('csv'), id: 'CSV' },
                  ]}
                  className=""
                  required
                />
                <InputField
                  name="communicationPassword"
                  label={t('communicationPassword')}
                  id="communicationPassword"
                  type="text"
                  value={addDevice.communicationPassword}
                  innerIconStatus={false}
                  onChangeFunction={onChangeValue}
                />
              </div>
            </div>
            <div className="col-3">
              <GroupSwitchButton
                label="RS485 Address Code Figure:"
                leftText="ON"
                rightText="KE"
                name="rs485AddressCodeFigure"
                value={addDevice?.rs485AddressCodeFigure}
                changeFunction={changeSwitch}
                switchCount={8}
                disabled={6}
              />
            </div>
          </div>
        )}
        <div className="single-row-1">
          <SelectBoxField
            name="iconType"
            label={t('iconType')}
            id="iconType"
            value={addDevice.iconType}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[
              { label: t('excel'), id: 'XLS' },
              { label: t('pdf'), id: 'PDF' },
              { label: t('csv'), id: 'CSV' },
            ]}
            className=""
            required
          />
          <SelectBoxField
            name="controlPanelType"
            label={t('controlPanelType')}
            id="controlPanelType"
            value={addDevice.controlPanelType}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[
              { label: t('excel'), id: 'XLS' },
              { label: t('pdf'), id: 'PDF' },
              { label: t('csv'), id: 'CSV' },
            ]}
            className=""
          />
          <SelectBoxField
            name="area"
            label={t('area')}
            id="area"
            value={addDevice.area}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[
              { label: t('excel'), id: 'XLS' },
              { label: t('pdf'), id: 'PDF' },
              { label: t('csv'), id: 'CSV' },
            ]}
            className=""
            required
          />
        </div>
        <div className="single-row">
          <SelectBoxField
            name="addToLevel"
            label={t('addToLevel')}
            id="addToLevel"
            value={addDevice.addToLevel}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[
              { label: t('excel'), id: 'XLS' },
              { label: t('pdf'), id: 'PDF' },
              { label: t('csv'), id: 'CSV' },
            ]}
            className=""
          />
          <CheckBoxInputField
            name="clearData"
            data={[
              {
                label: t('clearDataLabel'),
                id: 'yes',
                value: 'yes',
              },
            ]}
            value={addDevice.clearData}
            onChangeFunction={onChangeValue}
          />
        </div>
      </div>
      <div className="error-label">
        <div>
          <ClearDataIcon />
        </div>
        <span>{t('clearDataLabelDescription')}</span>
      </div>
      <div className="form-buttons-right">
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('cancel')}
          className="btn-default"
        />
        <Button
          onClickFunction={submit}
          title={t('ok')}
          className="btn-primary"
          disabled={disable}
        />
      </div>
    </div>
  );
}

export default AddDevice;
