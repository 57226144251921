import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import Button from '../../../shared-components/button/button';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import FileUpload from './file-upload';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';
import { AddMapInterface } from '../../../interface/map.interface';

function AddMap({ closeModal, update }: any) {
  const { t } = useTranslation();
  const [addMap, setAddMap] = useState<AddMapInterface>({
    name: '',
    areaName: '',
    mapPath: null,
    modifyPath: [],
  });
  const [disable, setDisable] = useState<boolean>(false);
  const onChangeValue = (name: string, value: any) => {
    setAddMap((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const removeImage = () => {
    setAddMap((prev: any) => ({
      ...prev,
      mapPath: null,
    }));
  };
  const submit = () => {
    console.log('submit');
  };
  useEffect(() => {
    if (addMap.name && addMap.areaName && addMap.mapPath) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [addMap]);
  return (
    <div className="add-map">
      <div className="flex-row">
        <InputField
          name="name"
          label={t('name')}
          id="name"
          type="text"
          value={addMap.name}
          innerIconStatus={false}
          onChangeFunction={onChangeValue}
          required
        />
        <SelectBoxField
          name="areaName"
          label={t('areaName')}
          id="areaName"
          value={addMap.areaName}
          onChangeFunction={(name, value) => onChangeValue(name, value.id)}
          data={[
            { label: t('excel'), id: 'XLS' },
            { label: t('pdf'), id: 'PDF' },
            { label: t('csv'), id: 'CSV' },
          ]}
          className=""
          required
        />
      </div>
      {update && (
        <CheckBoxInputField
          name="modifyPath"
          data={[
            {
              label: t('modifyPath'),
              id: 'yes',
              value: 'yes',
            },
          ]}
          position="right"
          value={addMap?.modifyPath}
          onChangeFunction={onChangeValue}
        />
      )}
      <FileUpload
        label={t('mapPath')}
        name="mapPath"
        value={addMap.mapPath}
        removeFunction={removeImage}
        onChangeFunction={onChangeValue}
        required
      />
      <div className="form-buttons-right">
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('cancel')}
          className="btn-default"
        />
        <Button
          onClickFunction={submit}
          title={t('save')}
          className="btn-primary"
          disabled={disable}
        />
      </div>
    </div>
  );
}

export default AddMap;
