import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import InputIpAddressField from '../../../shared-components/input-ip-address-field/input-ip-address-field';
import { CommunicationInterface } from '../../../interface/accessDevice.interface';

function ModifyIpAddress({ closeModal, data }: CommunicationInterface) {
  const { t } = useTranslation();

  const [ipConfig, setIpConfig] = useState({
    ipAddress: data?.ipAddress || '',
    subnetMask: data?.subnetMask || '',
    gatewayAddress: data?.gatewayAddress || '',
  });

  const onChangeValue = (name: string, value: any) => {
    setIpConfig((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submit = () => {
    console.log(ipConfig);
    closeModal();
  };

  return (
    <div className="modify-ip-address">
      <div className="form-row">
        <InputIpAddressField
          id="ipAddress"
          name="ipAddress"
          label={t('ipAddress')}
          type="text"
          value={ipConfig.ipAddress}
          onChangeFunction={onChangeValue}
          aria-label="IP Address"
        />
        <InputIpAddressField
          id="subnetMask"
          name="subnetMask"
          label={t('subnetMask')}
          type="text"
          value={ipConfig.subnetMask}
          onChangeFunction={onChangeValue}
          aria-label="Subnet Mask"
        />
        <InputIpAddressField
          id="gatewayAddress"
          name="gatewayAddress"
          label={t('gatewayAddress')}
          type="text"
          value={ipConfig.gatewayAddress}
          onChangeFunction={onChangeValue}
          aria-label="Gateway Address"
        />
      </div>

      <div className="modal-footer">
        <Button
          onClickFunction={closeModal}
          title={t('Cancel')}
          aria-label={t('Cancel')}
          className="btn-default"
        />
        <Button
          onClickFunction={submit}
          title={t('ok')}
          aria-label={t('OK')}
          className="btn-primary"
        />
      </div>
    </div>
  );
}

export default ModifyIpAddress;
