import { type UIEvent, useEffect, useRef, useState } from 'react';

// Use common modules
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnFiltersState,
  type MRT_SortingState,
  type MRT_RowVirtualizer,
  type MRT_RowSelectionState,
} from 'material-react-table';
import { createTheme, ThemeProvider, Typography } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

// Use interface
import { DataTableInterFace } from '../../interface/table.interface';

function Example({
  action = [],
  actionButtonStatus,
  columns,
  checkBoxActive,
  singleRowSelection = false,
  getFilterData = (val = {}) => {
    console.log('Default filter data:', val);
  },
  getSelectedData = () => {},
  getSelectedFullData,
  fetchNextPage,
  assignFilterValue = {},
  assignSelectedData = [],
  isFetching,
  values,
  fixedColumn = [],
  totalRecords,
  height = 0,
  enableColumnFilters = false,
  enableFullScreenToggle = true,
  enableDensityToggle = true,
  enableColumnPinning = true,
  enableHiding = true,
  enableGlobalFilter = true,
  showCustomActions = true,
}: DataTableInterFace) {
  const { t } = useTranslation();
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const rowVirtualizerInstanceRef = useRef<MRT_RowVirtualizer>(null);
  const defaultSelectedData =
    assignSelectedData.length > 0
      ? assignSelectedData.reduce((a, v) => ({ ...a, [v]: true }), {})
      : {};

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    assignFilterValue.columnFilters || [],
  );
  const [globalFilter, setGlobalFilter] = useState<string>(assignFilterValue.globalFilter || '');
  const [sorting, setSorting] = useState<MRT_SortingState>(assignFilterValue.sorting || []);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>(defaultSelectedData);
  const isLoading = false;
  const theme = createTheme({
    typography: {
      fontFamily: 'Urbanist, sans-serif',
    },
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontFamily: 'Urbanist, sans-serif',
            color: '#696C6F',
          },
          head: {
            color: '#292D31',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            boxShadow: '0px 1px 8px 0px #0000001A',
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            boxShadow: '0px 1px 8px 0px #0000001A',
          },
        },
      },
    },
  });
  const getFilterFormat = () => {
    const filter: any = {};
    columnFilters.forEach((fil: any) => {
      filter[fil.id] = fil.value;
    });
    if (globalFilter) {
      filter.search = globalFilter;
    }
    if (sorting.length > 0) {
      filter.sortField = sorting[0].id;
      filter.sortOrder = sorting[0].desc ? 'desc' : 'asc';
    }
    return filter;
  };

  const fetchMoreOnBottomReached = (containerRefElement?: HTMLDivElement | null) => {
    if (containerRefElement) {
      const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
      if (
        scrollHeight - scrollTop - clientHeight < 0 &&
        !isFetching &&
        values.length < totalRecords
      ) {
        fetchNextPage();
      }
    }
  };

  const actionButtons = (rowData: any) =>
    action?.map((button: any) => {
      // Check if the label is 'delete' and disable the button if delete is false in the row data
      const isDeleteDisabled =
        button.label === t('delete') && rowData.delete === false && button.disabled;

      return (
        <div
          className={`table-menu ${isDeleteDisabled ? 'disabled' : ''}`}
          onClick={() => {
            if (!isDeleteDisabled) {
              button.function(rowData);
            }
          }}
          role="presentation">
          {button.label}
        </div>
      );
    });
  useEffect(() => {
    try {
      getFilterData({
        filter: getFilterFormat(),
        columnFilters,
        globalFilter,
        sorting,
      });
    } catch (error) {
      console.error(error);
    }
  }, [sorting, columnFilters, globalFilter]);
  // a check on mount to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [values]);

  useEffect(() => {
    setRowSelection(defaultSelectedData);
  }, [assignSelectedData]);

  const table = useMaterialReactTable({
    columns,
    data: values,
    enablePagination: false,
    enableColumnPinning,
    enableRowNumbers: false,
    enableColumnFilters,
    enableRowVirtualization: true,
    manualFiltering: true,
    enableRowActions: actionButtonStatus,
    enableFullScreenToggle,
    enableDensityToggle,
    enableHiding,
    enableGlobalFilter,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: t('action'),
      },
    },
    positionActionsColumn: 'last',
    muiTableContainerProps: {
      ref: tableContainerRef,
      sx: { maxHeight: `${window.innerHeight - height}px` },
      onScroll: (event: UIEvent<HTMLDivElement>) =>
        fetchMoreOnBottomReached(event.target as HTMLDivElement), // add an event listener to the table container element
    },
    renderRowActionMenuItems: ({ row }) => actionButtons(row.original),
    onColumnFiltersChange: setColumnFilters,

    // Sorting order method
    onSortingChange: setSorting,
    manualSorting: true,

    // Global filter textbox
    positionGlobalFilter: 'left',
    onGlobalFilterChange: setGlobalFilter,
    muiSearchTextFieldProps: {
      placeholder: 'Search all data',
      sx: { minWidth: '300px', borderRadius: '8px' },
      variant: 'outlined',
    },

    // Check box of records
    enableRowSelection: checkBoxActive,
    positionToolbarAlertBanner: showCustomActions ? 'bottom' : 'none',
    onRowSelectionChange: (
      updaterOrValue:
        | MRT_RowSelectionState
        | ((old: MRT_RowSelectionState) => MRT_RowSelectionState),
    ) => {
      setRowSelection((prevSelection) => {
        // Determine the new selection state
        let newSelection =
          typeof updaterOrValue === 'function'
            ? updaterOrValue(prevSelection) // If it's a function, pass in the previous selection
            : updaterOrValue; // Otherwise, it's the new selection object
        // Loop through the new selection data
        if (singleRowSelection) {
          // Keep only one row selected at a time for single row selection
          const selectedRowId = Object.keys(newSelection)[0];
          newSelection = selectedRowId ? { [selectedRowId]: true } : {};
        }
        Object.keys(newSelection).forEach((key) => {
          if (newSelection[key]) {
            // Add the row to the selection if it's checked
            newSelection[key] = true;
          } else {
            // Remove the row from the selection if it's unchecked
            delete newSelection[key];
          }
        });
        // Get the selected rows' data based on the new selection state
        const selectedRows = Object.keys(newSelection).map((rowId) =>
          values.find((row) => row.id === rowId),
        );
        // Pass the currently selected rows to getSelectedData
        getSelectedData(Object.keys(newSelection));
        if (getSelectedFullData) {
          getSelectedFullData(selectedRows);
        }
        // Return the updated selection state
        return newSelection;
      });
    },
    muiSelectCheckboxProps: {
      color: 'primary',
    },
    getRowId: (row) => row.id,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        setRowSelection((prev) => {
          // Create a new object to avoid mutating the state directly
          let newSelection = { ...prev };

          // Toggle the row's selection state
          if (singleRowSelection) {
            // For single row selection, clear previous selections and select only the clicked row
            newSelection = { [row.original.id]: true };
          } else if (!singleRowSelection) {
            // For multiple row selection, toggle the clicked row's selection state
            if (newSelection[row.original.id]) {
              delete newSelection[row.original.id];
            } else {
              newSelection[row.original.id] = true;
            }
          }

          // Get the full row data of the currently selected rows
          const selectedRows = Object.keys(newSelection).map((rowId) =>
            values.find((r) => r.id === rowId),
          );
          // Call the selection data functions with the updated selection
          getSelectedData(Object.keys(newSelection));
          if (getSelectedFullData) {
            getSelectedFullData(selectedRows);
          }

          // Return the updated selection state
          return newSelection;
        });
      },
    }),
    renderBottomToolbarCustomActions: () => (
      <Typography>
        {t('fetched')} {values.length} {t('of')} {totalRecords} {t('totalRows')}.
      </Typography>
    ),
    initialState: {
      columnPinning: { left: ['mrt-row-select', ...fixedColumn], right: ['mrt-row-actions'] },
      showColumnFilters: columnFilters.length > 0,
      showGlobalFilter: true,
    },
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      rowSelection,
      showAlertBanner: false,
      showProgressBars: isFetching,
      sorting,
    },
    rowVirtualizerInstanceRef, // get access to the virtualizer instance
    // rowVirtualizerOptions: { overscan: 4 },
    muiTableHeadCellProps: ({ column }) => ({
      sx: {
        backgroundColor: column.getIsPinned() ? 'grey' : '#F6F6F7',
      },
    }),
    layoutMode: 'grid',
    enableColumnResizing: true,
  });

  return (
    <ThemeProvider theme={theme}>
      <MaterialReactTable table={table} />
    </ThemeProvider>
  );
}

const queryClient = new QueryClient();

function DataTable(data: DataTableInterFace) {
  return (
    <QueryClientProvider client={queryClient}>
      <Example {...data} />
    </QueryClientProvider>
  );
}

export default DataTable;
