import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import Button from '../../../shared-components/button/button';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import SelectLinkage from './SelectLinkageDevice';
import LinkageTriggerConnections from './LinkageTriggerConnections';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';
import TabPages from '../../../shared-components/tab-pages/tab-pages';
import OutputPoint from './OutputPoint';
import VideoLinkage from './VideoLinkage';
import EmailLinkage from './EmailLinkage';
import Intrusion from './Intrusion';
import SendSms from './SendSms';
import Label from '../../../shared-components/label/Label';
import Line from './Line';
import Whatsapp from './whatsapp';
import { DeviceProps } from '../../../interface/accessByDepartment';

interface LinkageProps {
  closeModal: () => void;
  update?: any;
}
function AddLinkage({ closeModal, update }: LinkageProps) {
  const { t } = useTranslation();
  const tabs = [
    {
      key: 'outputPoint',
      title: t('outputPoint'),
      content: <OutputPoint />,
    },
    {
      key: 'videoLinkage',
      title: t('videoLinkage'),
      content: <VideoLinkage />,
    },
    {
      key: 'E-mail',
      title: t('E-mail'),
      content: <EmailLinkage />,
    },
    {
      key: 'intrusion',
      title: t('intrusion'),
      content: <Intrusion />,
    },
    {
      key: 'sendSMS',
      title: t('sendSMS'),
      content: <SendSms />,
    },
    {
      key: 'line',
      title: t('line'),
      content: <Line />,
    },
    {
      key: 'whatsapp',
      title: t('whatsapp'),
      content: <Whatsapp />,
    },
  ];
  const [value, setValue] = useState(
    update || {
      name: '',
      device: '',
      trigger: [],
      selectedDevice: [],
    },
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [disable, setDisable] = useState(false);
  // const [devices, setDevices] = useState<any[]>(tableData);
  const [selectedDevices, setSelectedDevices] = useState<any[]>([]);
  const [triggerConditions, setTriggerConditions] = useState<any[]>([]);
  const onChangeValue = (name: string, data: any) => {
    setValue((prev: any) => ({
      ...prev,
      [name]: data,
    }));
  };
  const tableColumns = [
    { label: t('deviceName'), id: 'deviceName' },
    { label: t('serialNumber'), id: 'serialNumber' },
  ];
  const changeSearch = () => {
    console.log('search');
  };
  const getSelectedDevice = (selected: DeviceProps[]) => {
    const deviceValue = selected.map((item: any) => ({
      label: item.deviceName,
      id: item.id,
      value: item.id,
    }));
    setSelectedDevices(deviceValue);
  };
  const handleClickDevice = () => {
    setModalView({
      title: t('selectLinkageDevice'),
      content: (
        <SelectLinkage
          searchValue=""
          changeSearch={changeSearch}
          tableColumns={tableColumns}
          getSelected={(selected: DeviceProps[]) => getSelectedDevice(selected)}
          closeModal={() => setModalOpen(false)}
        />
      ),
    });
    setModalOpen(true);
  };
  const addTriggerCondition = (val: any) => {
    const triggerValue = val.map((item: any) => ({
      label: item.eventName,
      id: item.id,
      value: item.id,
    }));
    setTriggerConditions(triggerValue);
    setValue((prev: any) => ({
      ...prev,
      trigger: triggerValue.map((item: any) => item.id),
    }));
  };
  const handleLinkageTrigger = () => {
    setModalView({
      title: t('chooseLinkageTriggerConnections'),
      content: (
        <LinkageTriggerConnections
          closeModal={() => setModalOpen(false)}
          addTriggerCondition={addTriggerCondition}
          initialValues={value?.trigger}
        />
      ),
    });
    setModalOpen(true);
  };
  useEffect(() => {
    if (value.name) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [value]);
  return (
    <div className="add-linkage flex-row column">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="flex-row" style={{ alignItems: 'center' }}>
        <div className="col">
          <InputField
            name="name"
            label={t('name')}
            id="name"
            type="text"
            value={value.name}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
          />
        </div>
        <div className="select-device-wrapper">
          <div className="select-device">
            <Button
              onClickFunction={handleClickDevice}
              title={t('selectDevice')}
              className="btn-outline-primary"
            />
            <div className="device-name">
              <p className="label">{t('SelectedDevice')}</p>
              <h4 className="value">{selectedDevices?.[0]?.label}</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-row linkage-box">
        <div className="box-wrapper">
          <div className="header">
            <Label title={t('linkageTriggerConditions')} required />
            <span className="add-link" role="presentation" onClick={handleLinkageTrigger}>
              {t('add')}
            </span>
          </div>
          <div className="box">
            <CheckBoxInputField
              name="trigger"
              data={triggerConditions}
              value={value?.trigger}
              onChangeFunction={onChangeValue}
              alignment="column"
            />
          </div>
        </div>
        <div className="box-wrapper">
          <div className="header">
            <Label title={t('inputPoint')} required />
          </div>
          <div className="box">
            <CheckBoxInputField
              name="selectedDevice"
              data={selectedDevices}
              value={value?.selectedDevice}
              onChangeFunction={onChangeValue}
              alignment="column"
            />
          </div>
        </div>
      </div>
      <div className="linkage-types">
        <TabPages tabs={tabs} defaultNumber={0} />
      </div>
      <div className="form-buttons-right">
        <Button
          onClickFunction={() => console.log('hi')}
          title={t('save&new')}
          className="btn-primary"
        />
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('cancel')}
          className="btn-default"
        />
        <Button
          onClickFunction={() => console.log('hi')}
          title={t('done')}
          className="btn-primary"
          disabled={disable}
        />
      </div>
    </div>
  );
}

AddLinkage.defaultProps = {
  update: [],
};

export default AddLinkage;
