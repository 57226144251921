import React from 'react';
import SwitchButton from '../switch-button/switch-button';
import { GroupSwitchButtonInterface } from '../../interface/common.interface';

function GroupSwitchButton({
  // name,
  label,
  leftText,
  rightText,
  value,
  changeFunction = () => {},
  switchCount = 0,
  disabled = 0,
}: GroupSwitchButtonInterface) {
  const switchArray = Array.from({ length: switchCount }, (_, i) => i + 1);
  return (
    <div className="group-switch-button">
      <span className="group-label">{label}</span>
      <div className="group-box">
        <div className="box-header">
          <span className="left">{leftText}</span>
          <span className="right">{rightText}</span>
        </div>
        <div className="switch-buttons">
          {switchArray.map((item) => (
            <div className="switch-wrapper" key={item}>
              <SwitchButton
                type="checkbox"
                name={`switch${item}`} // Unique names like 'switch1', 'switch2', etc.
                value={value[`switch${item}`] || false} // Get value from the parent state
                onChangeFunction={changeFunction} // Pass the change function
                id={item}
                disabled={item > disabled}
              />
              <span className="count-label">{item}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default GroupSwitchButton;
