import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import { nameValidation } from '../../../shared-components/form/validation';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';
import { ClearDataIcon } from '../../../assets/svgicons/svgicon';
import Button from '../../../shared-components/button/button';

function SearchAddDevice({ closeModal }: any) {
  const { t } = useTranslation();
  const [addDevice, setAddDevice] = useState({
    name: '',
    iconType: '',
    area: '',
    addToLevel: '',
    clearData: [],
  });
  console.log(closeModal);
  const onChangeValue = (name: string, value: any) => {
    setAddDevice((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const submit = () => {
    console.log('hi');
  };
  return (
    <div className="search-add-device">
      <div className="add-device-form">
        <div className="single-row">
          <InputField
            name="name"
            label={t('deviceName')}
            id="name"
            type="text"
            value={addDevice.name}
            required
            // readonly={!!data}
            errorStatus={addDevice.name !== '' && !nameValidation(1, 50, addDevice.name)}
            message={t('departmentNumberInvalid')}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
          />
          <SelectBoxField
            name="iconType"
            label={t('iconType')}
            id="iconType"
            value={addDevice.iconType}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[
              { label: t('door'), id: 'door' },
              { label: t('parkingBarrier'), id: 'parkingBarrier' },
              { label: t('flapBarrier'), id: 'flapBarrier' },
            ]}
            className=""
            required
          />
          <SelectBoxField
            name="area"
            label={t('area')}
            id="area"
            value={addDevice.area}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[
              { label: t('excel'), id: 'XLS' },
              { label: t('pdf'), id: 'PDF' },
              { label: t('csv'), id: 'CSV' },
            ]}
            className=""
            required
          />
        </div>
        <div className="single-row-1">
          <SelectBoxField
            name="addToLevel"
            label={t('addToLevel')}
            id="addToLevel"
            value={addDevice.addToLevel}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[
              { label: t('jed'), id: 'jed' },
              { label: t('My Testing'), id: 'myTesting' },
              { label: t('Parking'), id: 'parking' },
            ]}
            className=""
          />
          <CheckBoxInputField
            name="clearData"
            data={[
              {
                label: t('clearDataLabel'),
                id: 'yes',
                value: 'yes',
              },
            ]}
            value={addDevice.clearData}
            onChangeFunction={onChangeValue}
          />
        </div>
        <div className="error-label">
          <div>
            <ClearDataIcon />
          </div>
          <span>{t('clearDataLabelDescription')}</span>
        </div>
        <div className="form-buttons-right">
          <Button
            onClickFunction={() => {
              closeModal();
            }}
            title={t('cancel')}
            className="btn-default btn-bold"
          />
          <Button onClickFunction={submit} title={t('Done')} className="btn-primary" />
        </div>
      </div>
    </div>
  );
}

export default SearchAddDevice;
