import React from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../../shared-components/table/table';
import Button from '../../../shared-components/button/button';
import { ErrorIconTwo } from '../../../assets/svgicons/svgicon';

function ViewRulesOfDevices({ closeModal }: any) {
  const { t } = useTranslation();
  const datas = [
    {
      ruleType: t('Interlock'),
      description: t('notSet'),
      ruleDetails: t('none'),
    },
    {
      ruleType: t('linkage'),
      description: t('notSet'),
      ruleDetails: t('none'),
    },
    {
      ruleType: t('anti-Passback'),
      description: t('notSet'),
      ruleDetails: t('none'),
    },
    {
      ruleType: t('firstPersonNormallyOpen'),
      description: t('notSet'),
      ruleDetails: t('none'),
    },
    {
      ruleType: t('multiPersonOpeningDoor'),
      description: t('notSet'),
      ruleDetails: t('none'),
    },
    {
      ruleType: t('doorSensor'),
      description: t('notSet'),
      ruleDetails: t('2, Door Sensor (Normally Open), Door Sensor Delay (50 s)'),
    },
    {
      ruleType: t('activeTimeZone'),
      description: t('notSet'),
      ruleDetails: t('none'),
    },
    {
      ruleType: t('passageModeTimeZone'),
      description: t('notSet'),
      ruleDetails: t('none'),
    },
    {
      ruleType: t('accessLevels'),
      description: t('notSet'),
      ruleDetails: t('none'),
    },
    {
      ruleType: t('backgroundVerification'),
      description: t('notSet'),
      ruleDetails: t('none'),
    },
    {
      ruleType: t('globalAntiPassback'),
      description: t('notSet'),
      ruleDetails: t('none'),
    },
    {
      ruleType: t('globalInterlock'),
      description: t('notSet'),
      ruleDetails: t('none'),
    },
    {
      ruleType: t('globalLinkage'),
      description: t('notSet'),
      ruleDetails: t('none'),
    },
    {
      ruleType: t('personAvailability'),
      description: t('notSet'),
      ruleDetails: t('none'),
    },
    {
      ruleType: t('occupancyControl'),
      description: t('notSet'),
      ruleDetails: t('none'),
    },
  ];
  return (
    <div className="view-rulesOf-devices">
      <Table
        header={[
          { label: t('ruleType'), id: 'ruleType' },
          { label: t('description'), id: 'description' },
          { label: t('ruleDetails'), id: 'ruleDetails' },
        ]}
        value={datas} // Display the current issued cards
      />
      <div className="bottom">
        <div className="text-message">
          <div>
            <ErrorIconTwo />
          </div>
          <span className="text-warning">{t('linkageGlobalLinkageAntiPassbackError')}</span>
        </div>
        <Button onClickFunction={closeModal} title={t('close')} className="btn-primary" />
      </div>
    </div>
  );
}

export default ViewRulesOfDevices;
