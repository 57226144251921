import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/store';

// Use shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../shared-components/modal-box/modal-box';
import ConformationBox from '../../shared-components/conformation-box/conformation-box';
// Use assets svg icons

import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';
// Import card slice actions
import { fetchDeviceList, setSearchFilter } from '../../redux/slices/DeviceSlice';
import AddLevelModal from '../access-levels/components/add-levels';
import AccessControlSetting from './components/access-control-setting';
import AccessExport from './components/access-export';
import SynchronizeLevel from './components/synchronizeLevel';

function SetAccessByPerson() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { devices, status, totalDataCount, searchFilter, currentPageNo } = useAppSelector(
    (state: any) => state.device,
  );
  const [selectedData, setSelectedData] = useState<string[]>([]); // Add state to hold selected cards
  console.log(selectedData);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('accesslevels'),
    content: <>hi</>,
  });
  const [selectedCards, setSelectedCards] = useState<string[]>([]); // Add state to hold selected cards
  let handleEditOpen: any;

  const accessLevelColumnsField = [
    {
      accessorKey: 'personnelId',
      header: t('personnelId'),
      Cell: ({ row }: any) => (
        <div
          className="list-hyperlink"
          role="presentation"
          onClick={(event) => {
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.personnelId}
        </div>
      ),
    },
    {
      accessorKey: 'firstName',
      header: t('firstName'),
    },
    {
      accessorKey: 'lastName',
      header: t('lastName'),
    },
    {
      accessorKey: 'department',
      header: t('department'),
    },
    {
      accessorKey: 'deviceOperationRole',
      header: t('deviceOperationRole'),
    },
  ];

  const doorColumnsField = [
    {
      accessorKey: 'levelName',
      header: t('levelName'),
    },
    {
      accessorKey: 'areaName',
      header: t('areaName'),
    },
    {
      accessorKey: 'timeZone',
      header: t('timeZone'),
    },
  ];

  // Sample data for the access levels table (replace with actual data)
  const levelslist = [
    {
      personnelId: '617002502',
      firstName: '',
      lastName: '',
      department: 'Dep_Test',
      deviceOperationRole: 'Ordinary User',
    },
    {
      personnelId: '617002502',
      firstName: '',
      lastName: '',
      department: 'Dep_Test',
      deviceOperationRole: 'Ordinary User',
    },
  ];

  // Sample data for the doors table (replace with actual data)
  const doorsList = [{ levelName: 'Free Rotation', areaName: 'asd', timeZone: '2 to 10' }];

  const fetchData = async (params: any) => {
    try {
      if (status === 'success') {
        dispatch(fetchDeviceList({ ...params }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFilterData = (filter: any): any => {
    if (currentPageNo > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: 1, ...filter.filter });
    }
  };

  const fetchNextData = (): any => {
    fetchData({ pageNo: currentPageNo + 1, ...searchFilter.filter });
  };
  handleEditOpen = () => {
    setModalOpen(true);
    setModalView({
      title: t('editAttribute'),
      content: <AddLevelModal closeModal={() => setModalOpen(false)} />,
    });
  };
  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={() => {
                console.log('Delete confirmed for id:', val.id);
                setModalOpen(false);
              }}
            />
          ),
        });
      },
    },
  ];

  const exportData = (data: any) => {
    console.log(data);
    setModalOpen(false);
  };

  const moreActionButton = [
    {
      title: t('accessControlSetting'),
      clickFunction: (): void => {
        if (selectedCards.length > 0) {
          console.log(selectedData);
          setModalView({
            title: t('accessControlSetting'),
            content: (
              <AccessControlSetting
                closeModal={() => setModalOpen(false)}
                submit={() => {
                  /* Your submit handler */
                }}
                disable={false}
              />
            ),
          });
          setModalOpen(true);
        } else {
          setModalView({
            title: t('accessControlSetting'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        }
      },
    },
    {
      title: t('export'),
      clickFunction: (): void => {
        if (selectedCards.length > 0) {
          setModalView({
            title: t('export'),
            content: (
              <AccessExport closeModal={() => setModalOpen(false)} submitFunction={exportData} />
            ),
          });
          setModalOpen(true);
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        }
      },
    },
    {
      title: t('synchronizeLevel'),
      clickFunction: (): void => {
        if (selectedCards.length > 0) {
          setModalView({
            title: t('synchronizeLevel'),
            content: <SynchronizeLevel closeModal={() => setModalOpen(false)} />,
          });
          setModalOpen(true);
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        }
      },
    },
  ];

  useEffect(() => {
    if (devices.length === 0) {
      fetchData({ pageNo: 1 });
    }
  }, []);

  return (
    <div className="card-management">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessRule'),
          },
          {
            title: t('setAccessByPerson'),
          },
        ]}
      />
      <CardBox
        header={t('editPersonnelForLevels')}
        title={t('listOfPerson')}
        rightSide={
          <CardRightButtonGroup
            actions={moreActionButton}
            buttonIcon={false}
            buttonLabel=""
            buttonClass=""
            buttonStatus={false}
            buttonFunction={(): void => {}}
          />
        }>
        <DataTable
          actionButtonStatus={!modalOpen}
          action={actionButtons}
          columns={accessLevelColumnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedCards} // Assign selected cards
          getSelectedData={(val: any): any => setSelectedCards(val)} // Set selected cards
          getSelectedFullData={(val: any): any => setSelectedData(val)}
          checkBoxActive
          fetchNextPage={fetchNextData}
          // isFetching={status === 'loading'}
          values={levelslist}
          fixedColumn={['cardNo']}
          totalRecords={totalDataCount}
          height={411}
        />
        <h4>{t('browsePersonnel1000000(ZKTeco SG)FromLevels')}</h4>
        <div className="doorlist">
          <p>List of Levels</p>
        </div>
        <DataTable
          action={actionButtons}
          columns={doorColumnsField}
          values={doorsList}
          actionButtonStatus={!modalOpen}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedCards} // Assign selected cards
          getSelectedData={(val: any): any => setSelectedCards(val)} // Set selected cards
          checkBoxActive
          fetchNextPage={fetchNextData}
          // isFetching={status === 'loading'}
          fixedColumn={['cardNo']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default SetAccessByPerson;
