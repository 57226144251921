import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import { EditDoorInterface } from '../../../interface/device.interface';
import Button from '../../../shared-components/button/button';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import door from '../../../utils/api/door';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';

function EditDoor({ closeModal, data }: EditDoorInterface) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const verificationOptions = [
    { label: t('automaticIdentification'), id: 'auto' },
    { label: t('onlyPin'), id: 'onlyPin' },
    { label: t('onlyPassword'), id: 'onlyPassword' },
    { label: t('onlyCard'), id: 'common_verifyMode_onlyCard' },
    { label: t('card/password'), id: 'card/password' },
    { label: t('card+password'), id: 'card+password' },
    { label: t('face'), id: 'common_verifyMode_onlyFp' },
    { label: t('face+password'), id: 'face+password' },
    { label: t('face+card'), id: 'face+card' },
  ];
  const [editDoorValue, setEditDoorValue] = useState(data);
  const [disable, setDisable] = useState(false);
  const onChangeValue = (name: string, value: any) => {
    setEditDoorValue((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };
  const submit = () => {
    door
      .doorSave(editDoorValue)
      .then((response) => {
        if (response.data.code === 0) {
          dispatch(
            setNotificationError({
              error: t('doorSavedSuccessfully'),
              status: 200,
            }),
          );
          closeModal();
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };

  useEffect(() => {
    if (
      editDoorValue?.name &&
      editDoorValue?.timeZone &&
      editDoorValue?.verifyModeName &&
      editDoorValue?.lockDelay &&
      Number(editDoorValue?.sensorDelay) > 0 &&
      editDoorValue?.doorSensorStatus !== undefined
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [editDoorValue]);
  return (
    <div className="edit-door">
      <div className="grid-row">
        <div className="col">
          <InputField
            name="deviceName"
            label={t('deviceName')}
            id="name"
            type="text"
            value={editDoorValue.deviceName}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            disabled
            required
          />
        </div>
        <div className="col">
          <InputField
            name="doorNumber"
            label={t('doorNumber')}
            id="name"
            type="text"
            value={editDoorValue.doorNumber}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            disabled
            required
          />
        </div>
        <div className="col">
          <InputField
            name="operateInterval"
            label={t('operateInterval')}
            id="name"
            type="text"
            value={editDoorValue.operateInterval}
            disabled
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            required
            validation
            message={t('second(0-254)')}
          />
        </div>
        <div className="col">
          <InputField
            name="anti_passback_duration"
            label={t('antiPassbackDurationOfEntrance')}
            id="name"
            type="text"
            value={editDoorValue?.anti_passback_duration}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            disabled
            required
            validation
            message={t('minute(0-120)')}
          />
        </div>
        <div className="col">
          <InputField
            name="name"
            label={t('doorName')}
            id="name"
            type="text"
            value={editDoorValue?.name}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            required
          />
        </div>
        <div className="col">
          <SelectBoxField
            name="hostStatus"
            label={t('hostAccessStatus')}
            id="hostStatus"
            value={editDoorValue.hostStatus?.toString()}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[
              { label: t('entry'), id: '1' },
              { label: t('out'), id: '0' },
            ]}
            className=""
          />
        </div>
        <div className="col">
          <InputField
            name="lockDelay"
            label={t('lockOpenDuration')}
            id="lockDelay"
            type="text"
            value={editDoorValue?.lockDelay}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            required
            validation
            message={t('second(1-254)')}
          />
        </div>
        <div className="col">
          <SelectBoxField
            name="doorSensorType"
            label={t('doorSensorType')}
            id="doorSensorStatus"
            value={editDoorValue.doorSensorStatus?.toString()}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[
              { label: t('none'), id: 'none' },
              { label: t('normallyOpen'), id: '1' },
              { label: t('normallyClosed'), id: '0' },
            ]}
            className=""
            required
          />
        </div>
        <div className="col">
          <SelectBoxField
            name="verifyModeName"
            label={t('verificationMode')}
            id="verifyModeName"
            value={editDoorValue.verifyModeName}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={verificationOptions}
            className=""
            required
          />
        </div>
        <div className="col">
          <InputField
            name="durationPassword"
            label={t('duressPassword')}
            id="name"
            type="password"
            value={editDoorValue?.duressPassword}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            validation
            message={t('maximum6BitInteger')}
          />
        </div>
        <div className="col">
          <InputField
            name="sensorDelay"
            label={t('doorSensorDelay')}
            id="sensorDelay"
            type="text"
            value={editDoorValue?.sensorDelay}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            required
            disabled
          />
        </div>
        <div className="col">
          <InputField
            name="emergencyPassword"
            label={t('emergencyPassword')}
            id="name"
            type="text"
            value={editDoorValue?.emergencyPassword}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            validation
            message={t('8BitInteger')}
          />
        </div>
        <div className="col">
          <SelectBoxField
            name="settingsCopy"
            label={t('settingsCopy')}
            id="settingsCopy"
            value={editDoorValue.settingsCopy}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[{ label: t('allDoorsAreStandAlone'), id: 'none' }]}
            className=""
            required
          />
        </div>
        <div className="col">
          <SelectBoxField
            name="timeZone"
            label={t('activeTimeZone')}
            id="timeZone"
            value={editDoorValue.timeZone}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[{ label: t('workingHours'), id: 'workingHours' }]}
            className=""
            required
          />
        </div>
        <div className="col">
          <SelectBoxField
            name="passModeTimeSegId"
            label={t('passageModeTimezone')}
            id="passageModeTimeZone"
            value={editDoorValue.passModeTimeSegId}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[{ label: t('24hoursAccessible'), id: '24hoursAccessible' }]}
            className=""
          />
        </div>
        <div className="col">
          <SelectBoxField
            name="slaveStatus"
            label={t('slaveOutOfState')}
            id="slaveStatus"
            value={editDoorValue.slaveStatus}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[
              { label: t('entry'), id: 'entry' },
              { label: t('out'), id: 'out' },
            ]}
            className=""
          />
        </div>
      </div>
      <div className="switch-wrap">
        <CheckBoxInputField
          name="disableAlarm"
          data={[
            {
              label: t('disableAlarmSounds'),
              id: 'yes',
              value: 'yes',
            },
          ]}
          value={editDoorValue.disableAlarm}
          onChangeFunction={onChangeValue}
        />
      </div>
      <div className="form-buttons-right">
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('cancel')}
          className="btn-default btn-bold"
        />
        <Button
          onClickFunction={submit}
          title={t('ok')}
          disabled={disable}
          className="btn-primary btn-bold"
        />
      </div>
    </div>
  );
}

export default EditDoor;
