import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import Table from '../../../shared-components/table/table';

function AddDoor({ closeModal }: any) {
  const { t } = useTranslation();
  const initialData = [
    {
      id: '0',
      doorName: '192.168.20.112',
      ownedDevice: '192.168.20.112',
      serialNumber: '6620223240005',
    },
    {
      id: '1',
      doorName: 'door 2',
      ownedDevice: '192.168.20.112',
      serialNumber: '6620223240005',
    },
    {
      id: '2',
      doorName: 'door 3',
      ownedDevice: '192.168.20.112',
      serialNumber: '6620223240005',
    },
  ];
  const [doors, setDoors] = useState<any[]>(initialData); // Available doors
  const [selectedDoors, setSelectedDoors] = useState<any[]>([]); // Selected doors
  const [selectedDoorIds, setSelectedDoorIds] = useState<string[]>([]); // Track selected door IDs for "doors" table
  const [selectedSelectedDoorIds, setSelectedSelectedDoorIds] = useState<string[]>([]); // Track selected door IDs for "selectedDoors" table

  // Function to handle door selection and move to the selected table
  const handleDoor = () => {
    const filterSelectData = doors.filter((item) => selectedDoorIds.includes(item.id));
    const remainingData = doors.filter((item) => !selectedDoorIds.includes(item.id));

    // Update states
    setDoors(remainingData); // Update remaining doors
    setSelectedDoors((prev) => [...prev, ...filterSelectData]); // Add selected doors to selected state
    setSelectedDoorIds([]); // Clear selected IDs after moving
  };

  // Function to handle moving selected doors back to available
  const handleSelectedDoor = () => {
    const unselectedDoors = selectedDoors.filter(
      (item) => !selectedSelectedDoorIds.includes(item.id),
    );
    const movedDoors = selectedDoors.filter((item) => selectedSelectedDoorIds.includes(item.id));

    setSelectedDoors(unselectedDoors); // Update selected doors
    setDoors((prev) => [...prev, ...movedDoors]); // Add back to available doors
    setSelectedSelectedDoorIds([]); // Clear selected IDs after moving
  };

  return (
    <div className="map-add-door">
      <div className="table-container">
        <div className="table-wrapper">
          <h4 className="title">{t('alternative')}</h4>
          <Table
            header={[
              { label: t('doorName'), id: 'doorName' },
              { label: t('ownedDevice'), id: 'ownedDevice' },
              { label: t('serialNumber'), id: 'serialNumber' },
            ]}
            value={doors}
            checkBoxStatus
            selectedValues={selectedDoorIds} // Track selected rows in "doors" table
            onSelect={(selected) => setSelectedDoorIds(selected)} // Handle selection in "doors" table
          />
          <Button
            onClickFunction={handleDoor}
            title={t('Move to Selected')}
            className="btn-primary"
            disabled={selectedDoorIds.length === 0} // Disable button if no items selected
          />
        </div>
        <div className="table-wrapper">
          <h4 className="title">{t('selected')}</h4>
          <Table
            header={[
              { label: t('doorName'), id: 'doorName' },
              { label: t('ownedDevice'), id: 'ownedDevice' },
              { label: t('serialNumber'), id: 'serialNumber' },
            ]}
            value={selectedDoors}
            checkBoxStatus
            selectedValues={selectedSelectedDoorIds} // Track selected rows in "selectedDoors" table
            onSelect={(selected) => setSelectedSelectedDoorIds(selected)} // Handle selection in "selectedDoors" table
          />
          <Button
            onClickFunction={handleSelectedDoor}
            title={t('Move to Available')}
            className="btn-primary"
            disabled={selectedSelectedDoorIds.length === 0} // Disable button if no items selected
          />
        </div>
      </div>
      <div className="form-buttons-right">
        <Button onClickFunction={closeModal} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={() => console.log('submit')}
          title={t('done')}
          className="btn-primary"
          disabled={initialData.length === 0}
        />
      </div>
    </div>
  );
}

export default AddDoor;
