import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import { AddIcon, FullScreenIcon, ZoomInIcon, ZoomOutIcon } from '../../assets/svgicons/svgicon';
import ModalBox from '../../shared-components/modal-box/modal-box';
import mapImage from '../../assets/images/map.png';
import pin from '../../assets/images/pin.png';
import AddMap from './components/add-map';
import DragDropImages from './components/dragAndDrop/dragdrop-Images';
import ConformationBox from '../../shared-components/conformation-box/conformation-box';
import { useAppDispatch } from '../../redux/store';
import { setNotificationError } from '../../redux/slices/NotificationSlices';
import AddDoor from './components/add-door';
import DataTable from '../../shared-components/data-table/data-table';
import LatestEventsPopup from '../realTime-monitoring/components/latest-events-popup';
import SelectBoxField from '../../shared-components/selectbox-field/selectbox-field';
import { DraggableImageInterface, MapCardInterface } from '../../interface/map.interface';

function Map() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('title'),
    content: <>hi</>,
  });
  const [zoomLevel, setZoomLevel] = useState<number>(1);
  const [area, setArea] = useState();
  const moreActionButton = [
    {
      title: t('edit'),
      clickFunction: (): void => {
        setModalOpen(true);
        setModalView({
          title: t('edit'),
          content: <AddMap closeModal={() => setModalOpen(false)} update />,
        });
      },
    },
    {
      title: t('delete'),
      clickFunction: (): void => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <ConformationBox
              closeModal={() => setModalOpen(false)}
              okFunction={() => setModalOpen(false)}
              title={t('areYouSureYouWantToDeleteTheCurrentMap')}
              buttonLabel={t('ok')}
              cancelTrue
            />
          ),
        });
      },
    },
    {
      title: t('savePositions'),
      clickFunction: (): void => {
        // setModalOpen(true);
        // setModalView({
        //   title: t('savePositions'),
        //   content: <>hii</>,
        // });
        dispatch(
          setNotificationError({
            error: 'The operation succeed',
            status: 200,
          }),
        );
      },
    },
    {
      title: t('addDoor'),
      clickFunction: (): void => {
        setModalOpen(true);
        setModalView({
          title: t('addDoor'),
          content: <AddDoor closeModal={() => setModalOpen(false)} />,
        });
      },
    },
    {
      title: t('addCamera'),
      clickFunction: (): void => {
        setModalOpen(true);
        setModalView({
          title: t('addCamera'),
          content: (
            <ConformationBox
              closeModal={() => setModalOpen(false)}
              okFunction={() => setModalOpen(false)}
              title={t('theSystemDoesNotDetectAVideoDevicePleaseAddVideoDevice')}
              buttonLabel={t('ok')}
            />
          ),
        });
      },
    },
  ];
  const handleClickZoom = (zoom: string) => {
    if (zoom === 'zoomIn') {
      setZoomLevel((prevZoomLevel) => Math.min(prevZoomLevel + 0.1, 3));
    } else {
      setZoomLevel((prevZoomLevel) => Math.max(prevZoomLevel - 0.1, 0.5));
    }
  };
  const mapActions = [
    {
      title: t('zoomIn'),
      icon: ZoomInIcon,
      clickFunction: (): void => handleClickZoom('zoomIn'),
    },
    {
      title: t('zoomOut'),
      icon: ZoomOutIcon,
      clickFunction: (): void => handleClickZoom('zoomOut'),
    },
    {
      title: t('fullScreen'),
      icon: FullScreenIcon,
      clickFunction: (): void => console.log('full screen'),
    },
  ];
  const handleClickEvent = () => {
    setModalView({
      title: t('queryTheLatestEventsFromTheDoor'),
      content: <LatestEventsPopup />,
    });
    setModalOpen(true);
  };
  const draggableImages: MapCardInterface[] = [
    {
      id: 1,
      src: pin,
      position: { x: 50, y: 50 },
      status: 'Online',
      serialNumber: '822223330568',
      number: '1',
      doorSensor: 'Unknown',
      relay: 'Unknown',
      alarm: 'None',
    },
  ];

  const mappedDraggableImages: DraggableImageInterface[] = draggableImages.map((image) => ({
    id: image.id,
    src: image.src,
    position: image.position,
    data: {
      status: image.status,
      serialNumber: image.serialNumber,
      number: image.number,
      doorSensor: image.doorSensor,
      relay: image.relay,
      alarm: image.alarm,
    },
    handleClickEvent,
    dragStart: false,
  }));

  // table
  const columnsField = [
    {
      accessorKey: 'time',
      header: t('time'),
      size: 120,
    },
    {
      accessorKey: 'area',
      header: t('area'),
      size: 100,
    },
    {
      accessorKey: 'device',
      header: t('device'),
      size: 100,
    },
    {
      accessorKey: 'eventPoint',
      header: t('eventPoint'),
      size: 100,
    },
    {
      accessorKey: 'eventDescription',
      header: t('eventDescription'),
      size: 100,
    },
    {
      accessorKey: 'cardNumber',
      header: t('cardNumber'),
      size: 120,
    },
    {
      accessorKey: 'person',
      header: t('person'),
      size: 100,
    },
    {
      accessorKey: 'readerName',
      header: t('readerName'),
      size: 100,
    },
    {
      accessorKey: 'verification',
      header: t('verification'),
      size: 120,
    },
  ];
  const fetchNextData = (): any => {
    console.log('Fetching next page data...');
  };
  return (
    <div className="device-map">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessDevice'),
          },
          {
            title: t('map'),
          },
        ]}
      />
      <div className="grid-container">
        <div className="header">
          <CardBox
            title={t('map')}
            rightSide={
              <CardRightButtonGroup
                actions={moreActionButton}
                buttonIcon={<AddIcon color="#ffffff" />}
                buttonLabel={t('new')}
                buttonClass="btn-primary btn-sm"
                buttonStatus
                buttonFunction={(): void => {
                  setModalOpen(true);
                  setModalView({
                    title: t('new'),
                    content: <AddMap closeModal={() => setModalOpen(false)} />,
                  });
                }}
              />
            }
          />
        </div>
        <div className="left">
          <CardBox title={t('mapIndex')}>
            <SelectBoxField
              name="area"
              label={t('selectArea')}
              id="area"
              value={area}
              onChangeFunction={(name, value: any) => setArea(value.id)}
              data={[
                { label: t('area 1'), id: 'area 1' },
                { label: t('area 2'), id: 'area 2' },
                { label: t('area 3'), id: 'area 3' },
              ]}
              className=""
            />
          </CardBox>
        </div>
        <div className="right-top">
          <div className="map-card">
            <div className="card-header">
              <span className="title">{t('Area 1')}</span>
              <div className="header-right">
                <div className="button-list">
                  {mapActions.map((item: any) => (
                    <div
                      key={item.title}
                      className="button-item"
                      onClick={item.clickFunction}
                      role="presentation">
                      <item.icon />
                      <span>{item.title}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="map-card-body">
              <div
                className="image-container"
                style={{
                  transform: `scale(${zoomLevel})`,
                  transformOrigin: '0px 0px 0px',
                  transition: 'transform 0.3s ease',
                }}>
                <DragDropImages
                  backgroundImage={mapImage}
                  draggableImages={mappedDraggableImages}
                  handleClickEvent={handleClickEvent}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="right-bottom">
          <CardBox title={t('real-time-events')}>
            <DataTable
              actionButtonStatus={false} // Disable action buttons when modal is open
              columns={columnsField}
              enableGlobalFilter={false}
              checkBoxActive={false}
              fetchNextPage={fetchNextData}
              values={[]} // Use the actual data here
              fixedColumn={['doorName']}
              totalRecords={0}
              height={411}
            />
          </CardBox>
        </div>
      </div>
    </div>
  );
}

export default Map;
