import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';
// import SpinnerLoader from '../../../shared-components/spinnerLoader/spinner-loader';
import { sortValuesByStatus } from '../../../utils/helpers';

function SynchronizeAllDataToDevice({ closeModal, selectedValues }: any) {
  const { t } = useTranslation();
  const checkboxData = [
    {
      label: t('Access Authority'),
      id: 'accessAuthority',
      value: 'accessAuthority',
    },
    {
      label: t('timeZoneHoliday'),
      id: 'timeZoneHoliday',
      value: 'timeZoneHoliday',
    },
    {
      label: t('doorParameters'),
      id: 'doorParameters',
      value: 'doorParameters',
    },
    {
      label: t('antiPassback'),
      id: 'antiPassback',
      value: 'antiPassback',
    },
    {
      label: t('firstPersonOpenDoor'),
      id: 'firstPersonOpenDoor',
      value: 'firstPersonOpenDoor',
    },
    {
      label: t('multiPersonOpenDoor'),
      id: 'multiPersonOpenDoor',
      value: 'multiPersonOpenDoor',
    },
    {
      label: t('linkage'),
      id: 'linkage',
      value: 'linkage',
    },
    {
      label: t('bgVerificationOptions'),
      id: 'bgVerificationOptions',
      value: 'bgVerificationOptions',
    },
  ];
  const [showUpgrade, setShowUpgrade] = useState(true);
  const [value, setValue] = useState({
    devices: [],
  });
  const [selectText, setSelectText] = useState(t('selectAll'));
  const onChangeValue = (name: string, values: any) => {
    setValue((prev: any) => ({
      ...prev,
      [name]: values,
    }));
  };
  const clickSynchronize = () => {
    console.log('synchronize');
  };
  const clickSelectAllDevice = () => {
    if (value?.devices?.length === checkboxData.length) {
      // All devices are selected, so unselect all
      setValue((prevValue: any) => ({
        ...prevValue,
        devices: [], // Clear the `devices` array
      }));
      setSelectText(t('selectAll'));
    } else {
      const allDeviceValues = checkboxData?.map((device: any) => device.value);
      setValue((prevValue: any) => ({
        ...prevValue, // Keep other values like `standAlone` unchanged
        devices: allDeviceValues, // Select all devices
      }));
      setSelectText(t('unselectAll'));
    }
  };
  /**
   * @sortValuesByStatus - Helper to sort devices based on their online/offline/disabled status
   * @popupErrorMessages - Helper to assign a message based on device status
   */
  const selectedDevices = selectedValues.sort(sortValuesByStatus);
  return (
    <div className="device-popup" style={{ width: '47vw' }}>
      <div className="row">
        <div className="column-1">
          <h3 className="title">{t('selectedDevice')}</h3>
          <div className="box">
            {selectedDevices.map(
              (device: any) =>
                device?.status === 'online' && (
                  <RadioButtonField
                    label=""
                    name="onlineStatus"
                    alignment="column"
                    data={[
                      {
                        label: 'The devices which have disabled background verification',
                        id: 'on',
                        value: 'on',
                      },
                    ]}
                    value="on"
                    onChangeFunction={() => {}}
                  />
                ),
            )}
            {selectedDevices.filter((device: any) => device?.status === 'offline').length > 0 && (
              <div>
                <h4 className="text-warning">{t('theOfflineDevicesCanNotBeOperated')}</h4>
                <span className="text-warning">
                  {selectedDevices
                    .filter((item: any) => item?.status === 'offline')
                    .map((item: any) => item?.name)
                    .join(', ')}
                </span>
              </div>
            )}
            {selectedDevices.filter((device: any) => device?.status === 'disable').length > 0 && (
              <div>
                <h4 className="text-warning">{t('theDisabledDevicesCanNotBeOperated')}</h4>
                <span className="text-warning">
                  {selectedDevices
                    .filter((item: any) => item?.status === 'disable')
                    .map((item: any) => item?.name)
                    .join(', ')}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="column-1">
          <div className="checkbox-wrapper">
            <div>
              <Button
                onClickFunction={clickSelectAllDevice}
                title={selectText}
                className="btn-primary"
              />
            </div>
            <CheckBoxInputField
              label=""
              name="devices"
              alignment="warp grid-2"
              required
              data={checkboxData}
              value={value.devices}
              onChangeFunction={onChangeValue}
            />
          </div>
          <div className="upgrading-content">
            <div className="left">
              <Button
                onClickFunction={() => setShowUpgrade(!showUpgrade)}
                title={t('hideInfo')}
                className="btn-primary"
              />
            </div>
            <div className="right">
              {/* <SpinnerLoader /> */}
              <div>
                <div className="form-buttons-right">
                  <Button
                    onClickFunction={clickSynchronize}
                    title={t('synchronize')}
                    className="btn-primary"
                    disabled
                  />
                  <Button
                    onClickFunction={() => {
                      closeModal();
                    }}
                    title={t('close')}
                    className="btn-default"
                  />
                </div>
              </div>
            </div>
          </div>
          {showUpgrade && (
            <div className="box">
              <span>Data Processing, Please Wait.....</span>
              <span>Admin : Data Synchronizing, Please Wait.....</span>
              <span>Admin : Device Synchronous Data Commands Sent Successfully.....</span>
              <span>Completed</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SynchronizeAllDataToDevice;
